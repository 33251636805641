import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from "../services/auth.service";
import { UserService } from "../services/user.service";
import { Role } from "../models/role";
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private auth: AuthService,
        private userService: UserService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
        // Handle auth service logic - https://jasonwatmore.com/post/2019/08/06/angular-8-role-based-authorization-tutorial-with-example
        return this.auth.checkLoginStatus()
            .then((value) => {
                //console.log("Value from checkLoginStatus from service in authGuard is", value);
                if (value) return value;
            })
            .then((user: any) => {
                // Check for authorization
                // Call userService and check if authorized.
                if (route.data.role) {
                    return this.userService.isAuthorizedWithRoles(user, route.data.role);
                }
                return this.userService.isAuthorized(user);
            })
            .then((bValue) => {
                // If user is present then check authorized property.
                if (bValue) {
                    return true;
                }
                else {
                    this.router.navigate(["/noauth"], { queryParams: { returnUrl: state.url }, skipLocationChange: true });
                    return false;
                }
            })
            .catch((error) => {
                console.log(`Inside auth guard catch - ${error}`);
                this.router.navigate(["/noauth"], { queryParams: { returnUrl: state.url }, skipLocationChange: true });
                return false;
            })
            .catch((error) => {
                console.log("Error in auth guard", error);
                this.router.navigate(["/login"], { queryParams: { returnUrl: state.url }, skipLocationChange: true });
                return false;
            });
    }
}