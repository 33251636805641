import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  AppheaderComponent, LoginComponent, HomeComponent, ManagerComponent,
  LogoutComponent, ProductsComponent, ProductComponent, AddProductComponent,
  AddInventoryComponent, ModifyQuantityComponent, NoAuthComponent, ProductLogComponent,
  InventoryMgmtComponent, LabourMgmtComponent, LabourFormComponent, LabourListComponent,
  LedgerMainComponent, LedgerFormComponent, LedgerListComponent, InvoicesComponent,
  NewinvoiceComponent, ReviewinvoiceComponent, PaymentDialogComponent, LedgerDashboardComponent,
  LedgerCottonFormComponent, CreditComponent, CustomersComponent, ReportsComponent, 
  SaudadashboardComponent, NewsaudaComponent, EditsaudaComponent
} from "./components";
import { inrPipe } from './pipes';
import {
  AuthService, UserService, InventoryService, LabourService,
  LabourDataService, LedgerService, LedgerDataService, SgService,
  InvoiceService, CustomerService, SaudaService
} from "./services";

import { AuthGuard as AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { AuthGuard } from "./helpers";

import { Role } from "./models/role";

import { GlobalConstants } from "./common";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'inv',
    component: InventoryMgmtComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lbr',
    component: LabourMgmtComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ledger',
    component: LedgerDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ledgermain',
    component: LedgerMainComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'invoice',
    component: InvoicesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ledgerentry',
    component: LedgerFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cottonledgerentry',
    component: LedgerCottonFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'newinvoice',
    component: NewinvoiceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'invoice/:id',
    component: ReviewinvoiceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'log/:pid',
    component: ProductLogComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'noauth',
    component: NoAuthComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'manager',
    component: ManagerComponent,
    canActivate: [AuthGuard],
    data: { role: Role.Manager }
  },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [AuthGuard],
    data: { role: Role.Manager }
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard],
    data: { role: Role.Manager }
  },
  {
    path: 'sauda',
    component: SaudadashboardComponent,
    canActivate: [AuthGuard],
    data: { role: Role.Manager }
  },
  {
    path: 'newsauda',
    component: NewsaudaComponent,
    canActivate: [AuthGuard],
    data: { role: Role.Manager }
  },
  {
    path: 'sauda/:id',
    component: EditsaudaComponent,
    canActivate: [AuthGuard],
    data: { role: Role.Manager }
  },

  // Redirect to home otherwise
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  inrPipe,
  AppheaderComponent,
  LoginComponent,
  HomeComponent,
  InventoryMgmtComponent,
  LedgerMainComponent, LedgerFormComponent, LedgerListComponent, LedgerDashboardComponent,
  LedgerCottonFormComponent,
  LabourMgmtComponent, LabourFormComponent, LabourListComponent,
  ManagerComponent,
  ProductComponent,
  AddProductComponent,
  AddInventoryComponent,
  ProductsComponent,
  ModifyQuantityComponent,
  ProductLogComponent,
  LogoutComponent,
  NoAuthComponent,
  InvoicesComponent,
  NewinvoiceComponent,
  ReviewinvoiceComponent,
  PaymentDialogComponent,
  CreditComponent,
  CustomersComponent,
  ReportsComponent,
  SaudadashboardComponent,
  NewsaudaComponent,
  EditsaudaComponent
];

export const entryComponents = [
  AddProductComponent,
  AddInventoryComponent,
  ModifyQuantityComponent
];

export const providerComponents = [
  AuthService,
  UserService,
  InventoryService, SgService,
  LabourService, LabourDataService,
  LedgerService, LedgerDataService,
  GlobalConstants, InvoiceService,
  CustomerService, SaudaService  
];
