import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';

import { LedgerService, LedgerDataService } from "../../../services";
import { Ledger } from "../../../models/ledger";
import { CottonLedger } from "../../../models/cotton-ledger";

@Component({
  selector: 'app-ledger-list',
  templateUrl: './ledger-list.component.html',
  styleUrls: ['./ledger-list.component.scss']
})
export class LedgerListComponent implements OnInit {

  TList$: Observable<Ledger[]>;
  snackbarDurationInSeconds = 5;

  TransactionsSource: MatTableDataSource<Ledger>;
  TransactionColumns: string[] = ["refId", "payee", "transactionDate", "amount", "delete"];
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private Service: LedgerService,
    private DataService: LedgerDataService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.TList$ = this.Service.getTransactions().pipe(
      map(TList => {
        TList.forEach((item) => {
          item["refId"] = item["rstId"] || item["receiptId"];
        });
        return TList;
      })
    );
    this.TList$.subscribe(List => {
      this.TransactionsSource = new MatTableDataSource(List);
      this.TransactionsSource.sort = this.sort;
    });

  }

  // Delete ledger entry
  deleteEntry(entry) {
    this.Service.deleteLegerEntry(entry.id);
    this.openSnackBar(`Deleted ledger entry ${entry.id}`)
  }

  // Snackbar
  openSnackBar(message) {
    this._snackBar.open(message, '', {
      duration: this.snackbarDurationInSeconds * 1000
    });
  }

}
