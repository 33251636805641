<h1 mat-dialog-title>Add Inventory</h1>
<div mat-dialog-content>
    <div>
        <mat-form-field>
            <mat-label>Select Product</mat-label>
            <mat-select required [(ngModel)] = "data.pid"
            (ngModelChange) = "getProductInfo($event)">
                <mat-option 
                *ngFor="let product of products" 
                [value] = "product.id">{{ product.name }}
            </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf = "units">
        <mat-form-field>
            <mat-label>Enter Quantity in {{ units }}</mat-label>
            <input matInput required [(ngModel)] = "data.quantity">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>Additional Note</mat-label>
            <textarea matInput [(ngModel)] = "data.note"></textarea>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click) = "onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Submit</button>
</div>