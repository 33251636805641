<div class="container">
    <div class="lbr-list-wrapper">
        <h1>Workers Log</h1>
        <nav class="lbr-list">
            <ol>
                <li *ngFor="let lbr of lbrList$ | async" class="list-item">
                    <h2>{{ lbr.date | date: 'mediumDate' }} [{{ lbr.total }}]
                        <button mat-icon-button color="primary" (click)="editDetails(lbr)">
                            <mat-icon>create</mat-icon>
                        </button>
                    </h2>
                    <div class="group">
                        <div *ngFor="let group of lbr.groups; let g=index">
                            <h2>
                                <span class="highlight">{{ group.name }} [{{ group.total }}]</span>
                            </h2>
                            <div class="cat-item">
                                <div *ngFor="let cat of group.categories; let i = index" class="cat-box">
                                    <div>
                                        <span>{{ cat.category }} [{{ cat.total }}]</span>
                                        <div *ngFor="let sub of cat.subCategories; let j = index" class="sub-box">
                                            <span>{{ sub.subCategory }} [{{ sub.count }}]</span>
                                        </div>
                                    </div>
                                    <p class="note-box">{{ cat.note }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </li>
            </ol>
        </nav>
    </div>
</div>