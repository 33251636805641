import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { InvoicePayment } from 'src/app/models/invoice-payment';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit {
  maxDate: Date;

  constructor(
    public mdRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvoicePayment
  ) {
    this.data.createDateTime = new Date().getTime().toString();
    this.maxDate = new Date();
    this.data.mode = "D";
   }

  ngOnInit(): void {
  }

  cancel() {
    this.mdRef.close();
  }

}
