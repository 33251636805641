import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { UserService } from "../services/user.service";

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  invRef: firebase.default.database.Reference;
  productsRef: firebase.default.database.Reference;
  quantityRef: firebase.default.database.Reference;
  productsList: string = "inventory/products";
  quantityList: string = "inventory/quantity";
  logList: string = "inventory/log";
  logRef: firebase.default.database.Reference;

  localUserId: string;

  constructor(private db: AngularFireDatabase,
    private userService: UserService) {
    this.invRef = db.database.ref("inventory");
    this.productsRef = this.invRef.child("products");
    this.quantityRef = this.invRef.child("quantity");
    this.logRef = this.invRef.child("log");

    this.localUserId = this.userService.getLocalStorageOperator().uid;
  }

  getProductsList() {
    return this.db.list(this.productsList, ref => ref.orderByChild("name")).valueChanges();
  }

  // Same as products list, just without orderByChild
  getAllProducts() {
    return this.db.list(this.productsList).valueChanges();
  }

  getInventoryList() {
    return this.db.list(this.quantityList).valueChanges();
  }

  getInventoryDetails(pid) {
    return this.db.object(this.quantityList + "/" + pid).valueChanges();
  }

  getProductDetails(id: string) {
    // Return details for a product
    return this.db.object(this.productsList + "/" + id).valueChanges();
  }

  addProduct(product) {
    // Add new product
    product.createDateTime = new Date().getTime();
    product.createOperatorId = this.localUserId;
    this.productsRef.child(product.id).set(product)
      .then(() => {
        this.addLog({
          pid: product.id, message: "Added new product - " + product.name,
          type: "New Product"
        });
      });
  }

  addToInventory(item) {
    // Add to inventory
    item.createDateTime = new Date().getTime();
    item.createOperatorId = this.localUserId;
    this.quantityRef.child(item.pid).set(item)
      .then(() => {
        this.addLog({
          pid: item.pid, message: "Added to inventory - " + item.quantity + " " + item.units,
          type: "Add Item",
          note: item.note || ""
        });
      })
      .catch((error) => {
        console.log(`Error while adding new Inventory ${error}`);
      });
  }

  updateQuantity(action, item) {
    // Add to inventory
    item.updateDateTime = new Date().getTime();
    item.updateOperatorId = this.localUserId;
    this.quantityRef.child(item.pid).set(item)
      .then(() => {
        let actionTag = "";
        if (action === "Add") {
          actionTag = "Added";
        }
        else actionTag = "Used";
        this.addLog({
          pid: item.pid, type: "Update Item", action: actionTag,
          actionQuantity: item.lastActionQuantity,
          note: item.note || "",
          message: actionTag + " " + item.lastActionQuantity + " " + item.units + ". Balance " +
            item.quantity + " " + item.units
        });

      })
      .catch((error) => {
        console.log(`Error while updating Inventory ${error}`);
      });
  }

  addLog(message: {}) {
    // Add log
    message["createDateTime"] = new Date().getTime();
    message["createOperatorId"] = this.localUserId;
    this.logRef.push(message);
  }

  // Log for all products
  getInventoryLog() {
    this.getProductsList().subscribe((list: any) => {
      list.forEach((product) => {
        this.logRef.orderByChild("pid").equalTo(product.id).once("value", (snapshot) => {
          snapshot.forEach((data: any) => {
            let log = data.val();
            log.name = product.name;
          });
        }, (error) => {
          console.log("Error while getting log entry for product", error);
        });
      });
    }, (error) => {
      console.log("Error in getting product list", error);
    }, () => {
      console.log("Message from Observable complete");
    });
  }

  getInventoryLogByProduct(pid) {
    // Get log by product
    if (!pid) return;
    return this.db.list(this.logList, ref => ref.orderByChild("pid").equalTo(pid)).valueChanges();
    //.orderByChild("createDateTime")).valueChanges();
  }

}
