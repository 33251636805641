import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    custStore: AngularFirestoreCollection<any>;

    constructor(
        private afs: AngularFirestore) {
        this.custStore = afs.collection<any>("customers");
    }

    /**
     * Get customer list by name
     * @param {String} searchString A string to search for
     * @returns {Array} Returns the array with customers list
     */
    async getCustomerListByNameSearch(searchString) {
        let custData = [];
        if(!searchString) return custData;
        // Get list by querying customers list
        const snapshot = await this.custStore.ref
            // .where('name', '>=', searchString)
            // .where('name', '<=', searchString + '\uf8ff')
            .get();
        if (snapshot.empty) return custData;
        snapshot.forEach((doc) => {
            let cust = doc.data();
            if (!cust.name.includes(searchString.toUpperCase())) return;
            custData.push({ 'name': cust.name, 'GSTNumber': cust.GSTNumber });
        });
        return custData;
    }
}