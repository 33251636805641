<div class="container" id="log-container">
    <mat-toolbar>
        <mat-toolbar-row>
            <button (click) = "goHome()" mat-raised-button color="primary">Back</button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="list">
        <h1 class="list-header">Log for {{ name }}</h1>
        <nav class="log-list">
            <ol>
                <li *ngFor="let log of logs$ | async" class="log-item">
                    <mat-card appearance="outlined">
                        <mat-card-header>
                            <mat-card-title>{{ log.createDateTime | date: 'medium' }}</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <p><span class="label-special">Log:</span> {{ log.message }}</p>
                            <p *ngIf="log.note"><span class="label-special">Note:</span> {{ log.note }}</p>
                        </mat-card-content>
                    </mat-card>
                </li>
            </ol>
        </nav>
    </div>
</div>