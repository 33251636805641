import { Component, OnInit } from '@angular/core';

import { SgService } from '../../services/sg.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  reportStartDate: Date;
  reportEndDate: Date;
  dateLimit: Date;
  displayPurchaseAndSalesReportResults: boolean;
  displayPurchaseAndSalesReportBusyInd: boolean;
  purchasesAndSalesObject: {
    totalPurchaseValue?: number,
    purchaseValuePerTonne?: number,
    totalPurchaseWithGSTValue?: number,
    totalPurchaseWeight?: number,
    otherPurchaseValue?: number,
    totalGrossPurchaseWeight?: number,
    totalSalesValue?: number,
    otherSalesValue?: number,
    saleValuePerTonne?: number,
    baleWeight?: number,
    seedWeight?: number,
    baleWeightPercent?: number,
    seedWeightPercent?: number,
    stockWeight?: number,
    totalSalesWeight?: number,
    plAmount?: number,
    plPerTonne?: number,
    projectedPLAmount?: number,
    balesPrice?: number,
    seedPrice?: number
  };

  constructor(private sgService: SgService) {
    this.dateLimit = new Date();
    this.reportStartDate = new Date();
    this.reportEndDate = new Date();
    this.displayPurchaseAndSalesReportResults = false
    this.displayPurchaseAndSalesReportBusyInd = false
    this.purchasesAndSalesObject = {
      totalPurchaseValue: 0,
      purchaseValuePerTonne: 0,
      totalPurchaseWithGSTValue: 0,
      totalPurchaseWeight: 0,
      otherPurchaseValue: 0,
      totalSalesValue: 0,
      otherSalesValue: 0,
      saleValuePerTonne: 0,
      baleWeight: 0,
      seedWeight: 0,
      baleWeightPercent: 0,
      seedWeightPercent: 0,
      stockWeight: 0,
      totalSalesWeight: 0,
      plAmount: 0,
      plPerTonne: 0,
      projectedPLAmount: 0,
      balesPrice: 0,
      seedPrice: 0
    };
  }

  ngOnInit(): void {
  }

  getPurchaseAndSalesReportByDates() {
    this.displayPurchaseAndSalesReportResults = false;
    this.displayPurchaseAndSalesReportBusyInd = true;
    const startDate = `${this.reportStartDate.getFullYear()}-${this.reportStartDate.getMonth() + 1}-${this.reportStartDate.getDate()}`;
    const endDate = `${this.reportEndDate.getFullYear()}-${this.reportEndDate.getMonth() + 1}-${this.reportEndDate.getDate()}`;
    this.sgService.getPurchaseAndSalesReport(startDate, endDate)
      .then((res) => {
        res.subscribe({
          next: (data) => {
            this.purchasesAndSalesObject = data;
            // console.log(this.purchasesAndSalesObject);
            this.purchasesAndSalesObject.totalGrossPurchaseWeight = this.purchasesAndSalesObject.totalPurchaseWeight / 10;
            this.purchasesAndSalesObject.totalPurchaseWeight = this.purchasesAndSalesObject.totalGrossPurchaseWeight - (this.purchasesAndSalesObject.totalGrossPurchaseWeight * 2 / 100); // 2% wastage
            this.purchasesAndSalesObject.totalPurchaseWithGSTValue = this.purchasesAndSalesObject.totalPurchaseValue + (this.purchasesAndSalesObject.totalPurchaseValue / 20);
            this.purchasesAndSalesObject.purchaseValuePerTonne = this.purchasesAndSalesObject.totalPurchaseWithGSTValue / this.purchasesAndSalesObject.totalPurchaseWeight;
            this.purchasesAndSalesObject.baleWeight = this.purchasesAndSalesObject.baleWeight / 1000;
            this.purchasesAndSalesObject.seedWeight = this.purchasesAndSalesObject.seedWeight / 1000;
            this.purchasesAndSalesObject.totalSalesWeight = this.purchasesAndSalesObject.totalSalesWeight / 1000;
            this.purchasesAndSalesObject.baleWeightPercent = (this.purchasesAndSalesObject.baleWeight / this.purchasesAndSalesObject.totalSalesWeight) * 100;
            this.purchasesAndSalesObject.seedWeightPercent = (this.purchasesAndSalesObject.seedWeight / this.purchasesAndSalesObject.totalSalesWeight) * 100;
            this.purchasesAndSalesObject.stockWeight = this.purchasesAndSalesObject.totalPurchaseWeight - this.purchasesAndSalesObject.totalSalesWeight;
            this.purchasesAndSalesObject.saleValuePerTonne = this.purchasesAndSalesObject.totalSalesValue / this.purchasesAndSalesObject.totalSalesWeight;
            this.purchasesAndSalesObject.plPerTonne = this.purchasesAndSalesObject.saleValuePerTonne - this.purchasesAndSalesObject.purchaseValuePerTonne;
            this.purchasesAndSalesObject.plAmount = this.purchasesAndSalesObject.totalSalesWeight * (this.purchasesAndSalesObject.plPerTonne);
            this.purchasesAndSalesObject.plAmount = this.purchasesAndSalesObject.plAmount - this.purchasesAndSalesObject.otherPurchaseValue - this.purchasesAndSalesObject.otherSalesValue;
            // this.purchasesAndSalesObject.projectedPLAmount = this.calculatePLAmount();
            this.displayPurchaseAndSalesReportBusyInd = false;
            this.displayPurchaseAndSalesReportResults = true;
          },
          error: (error) => { error }
        });
      })
      .catch((error) => {
        console.log(`Error in getPurchaseAndSalesReportByDates - ${error}`)
      })
      .finally(() => {
        // Any final actions
      });
  }

  calculatePLAmount() {
    const avgBalesPrice = this.purchasesAndSalesObject.balesPrice / this.purchasesAndSalesObject.baleWeight;
    const avgSeedPrice = this.purchasesAndSalesObject.seedPrice / this.purchasesAndSalesObject.seedWeight;
    const balesProjectedPL = (((this.purchasesAndSalesObject.totalPurchaseWeight * 35) / 100) - this.purchasesAndSalesObject.baleWeight) * avgBalesPrice;
    const seedProjectedPL = (((this.purchasesAndSalesObject.totalPurchaseWeight * 65) / 100) - this.purchasesAndSalesObject.seedWeight) * avgSeedPrice;

    return balesProjectedPL + seedProjectedPL + this.purchasesAndSalesObject.plAmount;
  }

}
