import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'inr' })
export class inrPipe implements PipeTransform {
  transform(value: number | string): string {
    if (value) {
      value = Number(value);
      if (!isNaN(value)) {
        var formatted_value;
        formatted_value = new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'INR'}).format(value);
        return formatted_value;
      }
    }
  }
}
