<div class="ledger-form-header">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <span>Cotton Purchase Entry</span>
            <span class="toolbar-empty-space"></span>
            <!-- <button mat-raised-button (click)="gotoLedgerMain()">Cancel</button> -->
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<div class="container">

    <div class="ledger-form-body">

        <form [formGroup]="LedgerEntry" *ngIf="detailsSubmitted!==true; else formSubmitted">
            <h3>Enter Purchase Details</h3>
            <div class="two-column-grid">
                <mat-form-field>
                    <mat-label>Purchase Type</mat-label>
                    <mat-select formControlName="purchaseType" required>
                        <mat-option *ngFor="let option of purchaseTypeOptions" [value]=option.value>
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Payee Type</mat-label>
                    <mat-select formControlName="payeeType" required>
                        <mat-option *ngFor="let option of payeeTypeOptions" [value]=option.value>
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Payee</mat-label>
                    <input matInput formControlName="payee" required (input)="toUpper($event)">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Transaction Date</mat-label>
                    <input matInput formControlName="transactionDate" required readonly [max]="maxDate"
                        [matDatepicker]="TDate">
                    <mat-datepicker-toggle matSuffix [for]="TDate"></mat-datepicker-toggle>
                    <mat-datepicker #TDate disabled="false"></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Quality</mat-label>
                    <mat-select formControlName="quality" required>
                        <mat-option *ngFor="let option of qualityOptions" [value]=option.value>
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <mat-form-field>
                    <mat-label>Details/Comments</mat-label>
                    <input matInput formControlName="description" (input)="toUpper($event)">
                </mat-form-field> -->
                <mat-form-field *ngIf="LedgerEntry.value.purchaseType === 'Loose'">
                    <mat-label>RST ID</mat-label>
                    <input matInput formControlName="rstId" required (input)="toUpper($event)">
                </mat-form-field>
                <mat-form-field *ngIf="LedgerEntry.value.purchaseType === 'Tender'">
                    <mat-label>Receipt ID</mat-label>
                    <input matInput formControlName="receiptId" required (input)="toUpper($event)">
                </mat-form-field>
                <mat-form-field *ngIf="LedgerEntry.value.purchaseType === 'Tender'">
                    <mat-label>Tender Bag Type</mat-label>
                    <mat-select formControlName="tenderBagType" required>
                        <mat-option *ngFor="let option of tenderBagOptions" [value]=option.value>
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="LedgerEntry.value.purchaseType === 'Tender'"> 
                    <mat-label>Checks/Bags Count</mat-label>
                    <input type="number" matInput formControlName="tenderBagCount" required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Weight in KGS</mat-label>
                    <input type="number" matInput formControlName="weight" required>
                </mat-form-field>
                <mat-form-field *ngIf="LedgerEntry.value.purchaseType === 'Tender'">
                    <mat-label>Reduce Weight By</mat-label>
                    <input type="number" matInput formControlName="reduceWeightBy">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Adjusted Weight in Quintals</mat-label>
                    <input type="number" matInput formControlName="adjustedWeight">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Rate Per Quintal</mat-label>
                    <input type="number" matInput formControlName="ratePerUnit" required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Amount In &#8377;</mat-label>
                    <input type="number" matInput formControlName="amount" required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Amount Source</mat-label>
                    <mat-select formControlName="amountSource" required>
                        <mat-option *ngFor="let option of amountSourceOptions" [value]=option.value>
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Reference</mat-label>
                    <input matInput formControlName="reference" (input)="toUpper($event)">
                </mat-form-field>
                <!-- <mat-form-field>
                    <mat-label>Cost Center</mat-label>
                    <input matInput formControlName="costCenter">
                </mat-form-field> -->
                <!-- <mat-form-field class="tag-list">
                    <mat-chip-list #chipList>
                        <mat-chip *ngFor="let Tag of Tags" [selectable]="true" [removable]="true"
                            (removed)="RemoveTag(Tag)">
                            {{Tag}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <mat-label>Tags</mat-label>
                    <input matInput formControlName="tags" 
                        placeholder="Enter Tags" 
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                        [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="AddTag($event)">
                </mat-form-field> -->
                <mat-form-field>
                    <mat-label>Note</mat-label>
                    <input matInput formControlName="note" (input)="toUpper($event)">
                </mat-form-field>
            </div>

            <div class="button-section padding-b-1x">
                <button mat-flat-button color="accent" (click)="gotoLedgerMain()">Cancel</button>
                <button mat-flat-button color="accent" (click)="reset()">Reset</button>
                <button mat-flat-button color="primary" (click)="save()">Add</button>
            </div>

        </form>

        <ng-template #formSubmitted>
            <div class="action-success centered">
                <div>
                    <mat-icon>check_circle_outline</mat-icon>
                </div>
                <p>Success</p>
                <p>
                    {{ LedgerEntry.value.payee | uppercase}}
                </p>
                <p>
                    {{ LedgerEntry.value.adjustedWeight }} Quintals
                </p>
                <p>
                    {{ LedgerEntry.value.amount | inr }}
                </p>
                <div class="button-section">
                    <button mat-flat-button color="primary" (click)="new()">Add New Transaction</button>
                    <button mat-raised-button color="accent" (click)="gotoLedgerMain()">Ledger Home</button>
                </div>
            </div>


        </ng-template>
    </div>
</div>

<!-- <p>Form Value: {{ LedgerEntry.value | json }}</p> -->