import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/compat/database';

import { UserService } from "../services/user.service";

@Injectable({
  providedIn: 'root'
})
export class LedgerService {

  ldrRef: firebase.default.database.Reference;
  ldrList: string = "ledger/data";
  logList: string = "ledger/log";
  ldrData: firebase.default.database.Reference;
  ldrArchive: firebase.default.database.Reference;
  logRef: firebase.default.database.Reference;

  localUserId: string;

  constructor(private db: AngularFireDatabase,
    private userService: UserService) {
    this.ldrRef = db.database.ref("ledger");
    this.ldrData = this.ldrRef.child("data");
    this.ldrArchive = this.ldrRef.child("archive");
    this.logRef = this.ldrRef.child("log");

    this.localUserId = this.userService.getLocalStorageOperator().uid;
  }

  // Add new entry
  add(ledger) {
    if (ledger) {
      let newTransaction = this.ldrData.push();
      ledger.id = newTransaction.key;
      ledger.createDateTime = new Date().getTime();
      ledger.createOperatorId = this.localUserId;
      return newTransaction.set(ledger)
        .then(() => {
          console.log(`Added new transaction`);
          this.addLog({
            id: ledger.id, message: "Added new transaction"
          });
        })
        .catch((error) => {
          console.log(`Transaction not added`);
        });
    }
  }

  // Edit ledger
  edit(ledger) { }

  // Delete ledger entry
  deleteLegerEntry(id) {
    if (!id || id === null) return;
    // Remove from realtime database by id
    this.ldrData.child(id).get()
    .then((data) => {
      // Move to archive first
      this.ldrArchive.child(id).set(data.val());
      // Delete the entry
      this.ldrData.child(id).remove();
    })
    .catch((error) => {
      console.log(`Error while getting entry - ${id} - ${error}`);
    });
  }

    /* Get methods */

  // Get list of transactions
  getTransactions() {
    return this.db.list(this.ldrList,
      ref => ref.orderByChild("transactionDate"))
      .valueChanges();
  }

  getTransactionsByCount(count: number) {
    if(!count) count = 10;
    return this.db.list(this.ldrList,
      ref => ref.orderByChild("transactionDate").limitToLast(count))
      .valueChanges();
  }

  // Add log entry
  addLog(message: {}) {
    // Add log
    message["createDateTime"] = new Date().getTime();
    message["createOperatorId"] = this.localUserId;
    this.logRef.push(message);
  }
}
