import { Injectable } from '@angular/core';

import { AngularFireAuth } from "@angular/fire/compat/auth";
import firebase from "firebase/compat/app";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private returningUserSubject: BehaviorSubject<firebase.User>;
  public returningUser: Observable<firebase.User>;

  constructor(private afAuth: AngularFireAuth) { 
    this.returningUserSubject = new BehaviorSubject<firebase.User>(JSON.parse(
      localStorage.getItem("currentUser")
    ));
    this.returningUser = this.returningUserSubject.asObservable();
  }
   
  // Login with Google
  login() {
    return this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
    .then((result) => {
      // console.log("Response of user sign in is", result);
      return result;
    })
    .catch((error) => {
      // console.log("Cannot sign in", error);
      return error;
    });
  }

  //Logout of app
  logout() {
    return this.afAuth.signOut()
    .then(() => {
      localStorage.removeItem("currentUser");
      this.returningUserSubject.next(null);
    });
  }

  checkLoginStatus() {
    return new Promise((resolve, reject) => {
      this.afAuth.onAuthStateChanged((user) => {
        if(user) {
          localStorage.setItem("currentUser", JSON.stringify(user));
          resolve(user);
        }
        reject("Not Logged In");
      }, ((error) => reject("Auth failed")));
    });
  }

  currentUser() {
    return this.afAuth.currentUser;
  }

  public returningUserValue() {
    return this.returningUserSubject.value;
  }

}
