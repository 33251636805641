<div class="list">
    <h1 class="list-header">Products in Inventory</h1>
    <nav class="products-list">
        <ul>
            <li *ngFor="let product of products$ | async" class="product-item">
                <mat-card appearance="outlined" *ngIf = "product.pid">
                    <mat-card-header>
                        <mat-card-title>
                            {{ product.name }} - {{ product.quantity }} {{ product.units }}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div *ngIf="product.lastAction">
                            <div class="last-action" [ngClass] = "product.class">
                                <div class="dt-small">{{ product.updateDateTime | date: 'medium' }}</div>
                                <span>{{ product.lastAction }} </span>
                                <span>{{ product.lastActionQuantity }} </span>
                                <span>{{ product.units }}</span>
                            </div>

                            <a mat-button color="primary" [routerLink]="['/log', product.pid]">
                                <mat-icon>arrow_forward</mat-icon>
                                Click for {{ product.name }} log
                            </a>
                        </div>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-stroked-button color="primary" (click)="openQtyDialog('Add', product)">Add
                            {{ product.name }}</button>
                        <button mat-raised-button color="primary" (click)="openQtyDialog('Use', product)">Use
                            {{ product.name }}</button>
                    </mat-card-actions>
                </mat-card>
            </li>
        </ul>
    </nav>
</div>