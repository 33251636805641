import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from "@angular/router";

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

import { LedgerService, LedgerDataService } from "../../../services";

@Component({
  selector: 'app-ledger-form',
  templateUrl: './ledger-form.component.html',
  styleUrls: ['./ledger-form.component.scss']
})
export class LedgerFormComponent implements OnInit {

  LedgerEntry: UntypedFormGroup;
  typeOptions: labelValue[];
  productOptions: labelValue[];
  qualityOptions: labelValue[];
  amountSourceOptions: labelValue[];
  payeeTypeOptions: labelValue[];
  detailsSubmitted: boolean = false;
  maxDate: Date;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  Tags: string[];

  constructor(private ngZone: NgZone, 
    private router: Router, 
    private fb: UntypedFormBuilder,
    private service: LedgerService, 
    private DataService: LedgerDataService) 
    { 
      this.typeOptions = [
        {label: "Debit - Outward", value: "D"},
        {label: "Credit - Inward", value:"C"}
      ];
      this.productOptions = [
        {label: "Cotton", value: "Cotton"},
        {label: "Other", value: "Other"}
      ];
      this.qualityOptions = [
        {label: "Top", value: "Top"},
        {label: "Medium", value: "Medium"},
        {label: "Low", value: "Low"},
        {label: "Not Applicable", value: "NA"},
        {label: "Other", value: "Other"}
      ];
      this.amountSourceOptions = [
        {label: "Cash", value: "Cash"},
        {label: "Cheque", value: "Cheque"},
        {label: "Digital", value: "Digital"},
        {label: "Other", value: "Other"}
      ];
      this.payeeTypeOptions = [
        {label: "Agent", value: "Agent"},
        {label: "Individual", value: "Individual"},
        {label: "Tender", value: "Tender"},
        {label: "Other", value: "Other"}
      ];
      this.addFormControls();
      this.Tags = [];
      this.maxDate = new Date();
    }

  ngOnInit(): void {
  }

  // Goto Main Ledger page
  gotoLedgerMain() {
    // Reset form if filled and cancelled
    this.ngZone.run(() => {
      this.router.navigate(["ledger"])
      .then((value) => {
        this.reset();
      });
    });
  }

  // Reset form
  reset() {
    this.LedgerEntry.reset();
    this.Tags = [];
  }

  // Add Form Controls from Model
  addFormControls() {
    this.LedgerEntry = this.fb.group({
      transactionDate: '',
      // type: 'D',
      product: '',
      // quality: '',
      units: '',
      label: '',
      description: '',
      payee: '',
      payeeType: '',
      ratePerUnit: '',
      totalPrice: '',
      amountSource: '',
      reference: '',
      costCenter: '',
      tags: '',
      note: ''
    });
  }

  // New Transaction
  new() {
    this.detailsSubmitted = false;
    this.addFormControls();
  }

  // Save Ledger Entry
  save() {
    if(this.LedgerEntry.invalid) return;
    let details = this.LedgerEntry.getRawValue();
    this.convertObjectValuesToUpperCase(details);
    details.transactionDate = details.transactionDate.getTime();
    details.tags = this.Tags.join();
    this.service.add(details)
    .then(() => {
      this.detailsSubmitted = true;
    })
    .catch((error) => {
      console.log("Transaction failed");
    });
  }

  // To upper case
  toUpper(event) {
    if (event) {
      event.target.value = event.target.value.toUpperCase();
    }
  }

  // Convert values to upper case
  convertObjectValuesToUpperCase(values) {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] !== "object") {
        values[key] = values[key].toString().toUpperCase();
      }
      else {
        this.convertObjectValuesToUpperCase(values[key]);
      }
      return values;
    });
  };

  // Update transaction
  update() {}

  // Void transaction
  void() {}

  // Tag events
  AddTag(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    // Add new Tag
    if ((value || '').trim()) {
      this.Tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  RemoveTag(Tag: string): void {
    const index = this.Tags.indexOf(Tag);

    if (index >= 0) {
      this.Tags.splice(index, 1);
    }
  }

}

// Label Value interface
interface labelValue {
  label: string,
  value: string
}