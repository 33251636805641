import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import * as i2 from '@angular/fire/auth';
import { user } from '@angular/fire/auth';
import * as i1 from '@angular/router';
import { of, pipe } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';
import { VERSION } from '@angular/fire';
import { registerVersion } from 'firebase/app';
const loggedIn = map(user => !!user);
class AuthGuard {
  router;
  auth;
  constructor(router, auth) {
    this.router = router;
    this.auth = auth;
  }
  canActivate = (next, state) => {
    const authPipeFactory = next.data.authGuardPipe || (() => loggedIn);
    return user(this.auth).pipe(take(1), authPipeFactory(next, state), map(can => {
      if (typeof can === 'boolean') {
        return can;
      } else if (Array.isArray(can)) {
        return this.router.createUrlTree(can);
      } else {
        // TODO(EdricChan03): Add tests
        return this.router.parseUrl(can);
      }
    }));
  };
  static ɵfac = function AuthGuard_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AuthGuard)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Auth));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AuthGuard,
    factory: AuthGuard.ɵfac,
    providedIn: 'any'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'any'
    }]
  }], () => [{
    type: i1.Router
  }, {
    type: i2.Auth
  }], null);
})();
const canActivate = pipe => ({
  canActivate: [AuthGuard],
  data: {
    authGuardPipe: pipe
  }
});
const isNotAnonymous = map(user => !!user && !user.isAnonymous);
const idTokenResult = switchMap(user => user ? user.getIdTokenResult() : of(null));
const emailVerified = map(user => !!user && user.emailVerified);
const customClaims = pipe(idTokenResult, map(idTokenResult => idTokenResult ? idTokenResult.claims : []));
const hasCustomClaim =
// eslint-disable-next-line no-prototype-builtins
claim => pipe(customClaims, map(claims => claims.hasOwnProperty(claim)));
const redirectUnauthorizedTo = redirect => pipe(loggedIn, map(loggedIn => loggedIn || redirect));
const redirectLoggedInTo = redirect => pipe(loggedIn, map(loggedIn => loggedIn && redirect || true));
class AuthGuardModule {
  constructor() {
    registerVersion('angularfire', VERSION.full, 'auth-guard');
  }
  static ɵfac = function AuthGuardModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AuthGuardModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AuthGuardModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [AuthGuard]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthGuardModule, [{
    type: NgModule,
    args: [{
      providers: [AuthGuard]
    }]
  }], () => [], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AuthGuard, AuthGuardModule, canActivate, customClaims, emailVerified, hasCustomClaim, idTokenResult, isNotAnonymous, loggedIn, redirectLoggedInTo, redirectUnauthorizedTo };
