import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SaudaService } from 'src/app/services';
import { Sauda } from 'src/app/models/sauda';

@Component({
  selector: 'app-editsauda',
  templateUrl: './editsauda.component.html',
  styleUrls: ['./editsauda.component.scss']
})
export class EditsaudaComponent {

  sauda: Sauda = {};
  id: string = "";
  errorMessage: string;
  statuses: string[];
  saudaSubmitted: boolean = false;

  constructor(private service: SaudaService, 
    private route: ActivatedRoute) {
    // Get sauda data
    this.id = this.route.snapshot.paramMap.get('id');
    this.getSauda(this.id);
    this.statuses = ['NEW', 'PENDING', 'COMPLETE', 'CANCEL'];
  }

  async getSauda(id) {
    const snap = await this.service.getSaudaEntry(id);
    if(snap.exists()) {
      this.sauda = snap.data();
    }
    else {
      this.errorMessage = "No doc exists";
    }
  }

  async updateSauda(id) {
    if(!id) return;
    let data = {
      updateDateTime: new Date().getTime(),
      status: this.sauda.status
    };
    try {
      await this.service.updateSauda(id, data);
      this.saudaSubmitted = true;
    }
    catch (error) {
      this.errorMessage = "Update failed. Please try again";
    }
  }

}
