import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { SaudaService } from 'src/app/services';

@Component({
  selector: 'app-newsauda',
  templateUrl: './newsauda.component.html',
  styleUrls: ['./newsauda.component.scss']
})
export class NewsaudaComponent {

  saudaForm: FormGroup;
  saudaSubmitted: boolean = false;
  errorMessage: string = "";
  Products: Object[];

  constructor(private service: SaudaService) {
    this.Products = [
      {
        label: "Cotton Bales", value: "B"
      },
      {
        label: "Cotton Seed", value: "S"
      },
      {
        label: "Other", value: "O"
      }];

      this.addFormControls();
  }

  addFormControls() {
    this.saudaForm = new FormGroup({
      refId: new FormControl(''),
      createDateTime: new FormControl(''),
      product: new FormControl(''),
      // quality: new FormControl(''),
      agent: new FormControl(''),
      company: new FormControl(''),
      saudaDateTime: new FormControl(''),
      price: new FormControl(''),
      // totalPrice: new FormControl(''),
      note: new FormControl('')
    });
  }

  async add() {
    if (this.saudaForm.valid) {
      this.saudaForm.patchValue({
        "createDateTime": new Date().getTime()
      });
      let obj = this.saudaForm.getRawValue();
      obj.status = "NEW";
      obj.saudaDateTime = obj.saudaDateTime.getTime();
      this.convertObjectValuesToUpperCase(obj);
      try {
        const doc = await this.service.postNewSauda(obj);
        this.saudaSubmitted = true;
        if (doc === null) throw `Sauda not created`;
      }
      catch (error) {
        this.errorMessage = "Sauda not created. Please try again"
      }
    }
    
  }

  convertObjectValuesToUpperCase(values) {
    Object.keys(values).forEach((key) => {
      if (values[key] && typeof values[key] !== "object") {
        values[key] = values[key].toString().toUpperCase();
      }
      else {
        this.convertObjectValuesToUpperCase(values[key]);
      }
      return values;
    });
  }

  reset() {
    this.saudaForm.reset();
  }

}
