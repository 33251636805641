import { Component, OnInit } from '@angular/core';

import { AuthService, UserService } from "../../services";

import { User } from "../../models/user";

@Component({
  selector: 'app-no-auth',
  templateUrl: './no-auth.component.html',
  styleUrls: ['./no-auth.component.scss']
})
export class NoAuthComponent implements OnInit {

  user: User;

  constructor(private auth: AuthService, 
    private userService: UserService) { 
      this.user = {
        id: "",
        lastLoggedOn: new Date(),
        authorized: false,
        data: {}
      };
    }

  ngOnInit(): void {
    this.auth.checkLoginStatus()
    .then((value) => {
      console.log("Login status value in no auth", value);
      this.saveNewUser(value);
    });
  }

  // New User functions
  newUser(user: any) {
    console.log("User in newUser function is", user);
    this.user.id = user.uid;
    this.user.data.id = user.uid;
    this.user.data.userInfo = user.providerData[0];
  }

  async saveNewUser(user) {
    // Call the service to save new user
    if (!user) return;
    console.log("Check for user existence", user);
    if (await this.isExistingUser(user)) return;
    console.log("New user, will save", user);
    this.newUser(user);
    return this.userService.saveNewUser(this.user);
  }

  isExistingUser(user): Promise<boolean> {
    // Check for user existence
    return new Promise((resolve, reject) => {
      if (!user) reject(false);
      this.userService.getUserDetails(user.uid).subscribe((operator: any) => {
        console.log("User in isExistingUser in no auth", operator);
        if (operator && operator.id !== undefined) resolve(true);
        resolve(false);
      }, (error) => { reject(false) });
    });
  }

}
