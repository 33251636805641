<div class="invoice-container">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <button mat-icon-button [routerLink]="['/']">
                <mat-icon>home</mat-icon>
            </button>
            <span>Invoice</span>
            <span class="toolbar-empty-space"></span>
            <!-- <button mat-raised-button (click)="newTransaction()">New</button> -->
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="main-content mat-elevation-z2 margin-1x" *ngIf="isMobileView === false; else mobileView">
        <table mat-table [dataSource]="invoicesSource" matSort>
            <ng-container matColumnDef="Date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let element"> {{element.createDateTime | date:"medium"}} </td>
            </ng-container>
            <ng-container matColumnDef="ID">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"> <span
                        *ngIf="element.tallyRefId && element.tallyRefId !== ''; else invoiceNumber">{{element.tallyRefId}}</span>
                    <ng-template #invoiceNumber>{{element.invoiceNumber}}</ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="Customer">
                <th mat-header-cell *matHeaderCellDef> Customer </th>
                <td mat-cell *matCellDef="let element"> {{element.customerAddress.name}} </td>
            </ng-container>
            <ng-container matColumnDef="Product">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Product </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.product=='B'; else seedBlock">Bales</span>
                    <ng-template #seedBlock>Seed</ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="Weight">
                <th mat-header-cell *matHeaderCellDef> Weight </th>
                <td *matCellDef="let element"> {{element.totalNetKGS}} </td>
            </ng-container>
            <ng-container matColumnDef="Price">
                <th mat-header-cell *matHeaderCellDef> Amount </th>
                <td mat-cell *matCellDef="let element"> {{element.totalPrice | inr}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row matRipple *matRowDef="let row; columns: columnsToDisplay;"
                (click)="getInvoice(row.invoiceNumber)" [ngClass]="row.product=='B'?'lightYellow-bg':'lightBlue-bg'">
            </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[15, 25, 50]" showFirstLastButtons></mat-paginator>
    </div>
    <ng-template #mobileView>
        <div class="main-content margin-1x">
            <mat-list>
                <mat-list-item *ngFor="let element of invList; last as last"
                    [ngClass]="element.product=='B'?'lightYellow-bg':'lightBlue-bg'"
                    (click)="getInvoice(element.invoiceNumber)">
                    <h3 matListItemTitle>(#<span
                            *ngIf="element.tallyRefId && element.tallyRefId !== ''; else invoiceNumber">{{element.tallyRefId}}</span>
                        <ng-template #invoiceNumber>{{element.invoiceNumber}}</ng-template>)
                        {{element.customerAddress.name}}
                    </h3>
                    <span matListItemLine>{{element.totalPrice | inr}} for {{element.totalNetKGS |
                        number:'1.0-2':'en-IN'}}KGS</span>
                    <span matListItemLine>{{element.createDateTime | date}}</span>
                    <mat-divider *ngIf="!last"></mat-divider>
                </mat-list-item>
            </mat-list>
        </div>
    </ng-template>
    <div class="new-fab-button">
        <button mat-fab color="primary" (click)="newInvoice()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>