<h1 mat-dialog-title>Record Payment</h1>
<div mat-dialog-content>
    <div class="flex-box">
        <mat-form-field>
            <mat-label>Transaction Date</mat-label>
            <input matInput [(ngModel)]="data.transactionDate" required readonly 
            [max]="maxDate" [matDatepicker]="TDate">
            <mat-datepicker-toggle matSuffix [for]="TDate"></mat-datepicker-toggle>
            <mat-datepicker #TDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Amount</mat-label>
            <input type="number" matInput required [(ngModel)]="data.amount">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Mode</mat-label>
            <mat-select [(ngModel)]="data.mode">
                <mat-option value="C">Cash</mat-option>
                <mat-option value="D">Digital</mat-option>
                <mat-option value="O">Other</mat-option>
            </mat-select>
          </mat-form-field>
        <mat-form-field>
            <mat-label>Reference</mat-label>
            <input matInput [(ngModel)]="data.reference">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Note</mat-label>
            <input matInput [(ngModel)]="data.note">
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Submit</button>
</div>