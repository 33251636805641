<div class="reports-dashboard">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <button mat-icon-button [routerLink]="['/']">
                <mat-icon>home</mat-icon>
            </button>
            <span>Reports Dashboard</span>
            <span class="toolbar-empty-space"></span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="container padding-lr-1x padding-b-1x margin-tb-1x">
        <h1 mat-dialog-title>Purchase and Sales</h1>
        <div class="two-column-grid">
            <mat-form-field>
                <mat-label>Start Date</mat-label>
                <input matInput [(ngModel)]="reportStartDate" required readonly [max]="dateLimit" [matDatepicker]="rsDate">
                <mat-datepicker-toggle matSuffix [for]="rsDate"></mat-datepicker-toggle>
                <mat-datepicker #rsDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>End Date</mat-label>
                <input matInput [(ngModel)]="reportEndDate" required readonly [max]="dateLimit" [matDatepicker]="reDate">
                <mat-datepicker-toggle matSuffix [for]="reDate"></mat-datepicker-toggle>
                <mat-datepicker #reDate></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="button-section padding-b-1x">
            <button mat-flat-button color="primary" (click)="getPurchaseAndSalesReportByDates()">Show Report</button>
        </div>
        <div>
            <mat-spinner *ngIf = "displayPurchaseAndSalesReportBusyInd"></mat-spinner>
        </div>
        <div class="report-card" *ngIf = "displayPurchaseAndSalesReportResults">
            <mat-card appearance="outlined" class="bg-light-blue">
                <mat-card-header>
                    <mat-card-title>Purchases</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p><span class="label">Gross Weight: </span>
                        <span>{{purchasesAndSalesObject.totalGrossPurchaseWeight | number:'1.0-2':'en-IN'}} Tonnes</span>
                    </p>
                    <p><span class="label">Weight: </span>
                        <span>{{purchasesAndSalesObject.totalPurchaseWeight | number:'1.0-2':'en-IN'}} Tonnes</span>
                    </p>
                    <p><span class="label">Amount: </span>
                        <span class="bold-text">{{purchasesAndSalesObject.totalPurchaseWithGSTValue | inr}}</span>
                    </p>
                    <p><span class="label">Per Tonne: </span>
                        <span>{{purchasesAndSalesObject.purchaseValuePerTonne | inr}}</span>
                    </p>
                </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined" class="bg-light-green">
                <mat-card-header>
                    <mat-card-title>Sales</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p><span class="label">Weight: </span>
                        <span>{{purchasesAndSalesObject.totalSalesWeight | number:'1.0-2': 'en-IN'}} Tonnes</span>
                    </p>
                    <p><span class="label">Amount: </span>
                        <span class="bold-text">{{purchasesAndSalesObject.totalSalesValue | inr}}</span>
                    </p>
                    <p><span class="label">Per Tonne: </span>
                        <span>{{purchasesAndSalesObject.saleValuePerTonne | inr}}</span>
                    </p>
                </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined" class="bg-light-orange">
                <mat-card-header>
                    <mat-card-title>Sales Weight</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p><span class="label">Bales: </span>
                        <span>{{purchasesAndSalesObject.baleWeight | number:'1.0-2': 'en-IN'}} Tonnes</span> <span> ({{ purchasesAndSalesObject.baleWeightPercent | number:'1.0-2': 'en-IN' }}%)</span>
                    </p>
                    <p><span class="label">Seed: </span>
                        <span>{{purchasesAndSalesObject.seedWeight | number:'1.0-2': 'en-IN'}} Tonnes</span> <span> ({{ purchasesAndSalesObject.seedWeightPercent | number:'1.0-2': 'en-IN' }}%)</span>
                    </p>
                    <p><span class="label">Stock: </span>
                        <span>{{purchasesAndSalesObject.stockWeight | number:'1.0-2': 'en-IN'}} Tonnes</span>
                    </p>
                </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined" class="bg-light-grey">
                <mat-card-header>
                    <mat-card-title>Sales Prices</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p><span class="label">Bales Avg: </span>
                        <span>{{(purchasesAndSalesObject.balesPrice / purchasesAndSalesObject.baleWeight) | inr}}</span>
                    </p>
                    <p><span class="label">Seed Avg: </span>
                        <span>{{(purchasesAndSalesObject.seedPrice / purchasesAndSalesObject.seedWeight) | inr}}</span>
                    </p>
                    <p><span class="label">P/L Per Tonne: </span>
                        <span class="bold-text">{{purchasesAndSalesObject.plPerTonne | inr}}</span>
                    </p>
                </mat-card-content>
            </mat-card>
            <div class="padding-lr-1x">
                <p><span class="label">P/L Amount: </span>
                    <span class="bold-text">{{purchasesAndSalesObject.plAmount | inr}}</span>
                </p>
                <!-- <p><span class="label">P/L Projected: </span>
                    <span class="bold-text">{{purchasesAndSalesObject.projectedPLAmount | inr}}</span>
                </p> -->
            </div>
        </div>
    </div>
</div>