import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ledger-main',
  templateUrl: './ledger-main.component.html',
  styleUrls: ['./ledger-main.component.scss']
})
export class LedgerMainComponent implements OnInit {

  constructor(private ngZone: NgZone, 
    private router: Router) { }

  ngOnInit(): void {
  }

  // Navigate to ledger form to
  // create new transaction
  newTransaction() {
    // Navigate to ledger form
    this.ngZone.run(() => {
      this.router.navigate(["/ledgerentry"], {skipLocationChange: true});
    });
  }

}
