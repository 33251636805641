import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from "@angular/router";

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

import { LedgerService, LedgerDataService } from "../../../services";

@Component({
  selector: 'app-ledger-form',
  templateUrl: './ledger-form.component.html',
  styleUrls: ['./ledger-form.component.scss']
})
export class LedgerCottonFormComponent implements OnInit {

  LedgerEntry: UntypedFormGroup;
  typeOptions: labelValue[];
  productOptions: labelValue[];
  qualityOptions: labelValue[];
  amountSourceOptions: labelValue[];
  payeeTypeOptions: labelValue[];
  tenderBagOptions: labelValue[];
  purchaseTypeOptions: labelValue[];
  detailsSubmitted: boolean = false;
  maxDate: Date;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  Tags: string[];

  constructor(private ngZone: NgZone, 
    private router: Router, 
    private fb: UntypedFormBuilder,
    private service: LedgerService, 
    private DataService: LedgerDataService) 
    { 
      this.typeOptions = [
        {label: "Debit - Outward", value: "D"},
        {label: "Credit - Inward", value:"C"}
      ];
      this.productOptions = [
        {label: "Cotton", value: "Cotton"},
        {label: "Other", value: "Other"}
      ];
      this.qualityOptions = [
        {label: "Top", value: "Top"},
        {label: "Medium", value: "Medium"},
        {label: "Low", value: "Low"},
        {label: "Other", value: "Other"}
      ];
      this.amountSourceOptions = [
        {label: "Cash", value: "Cash"},
        {label: "Cheque", value: "Cheque"},
        {label: "MC", value: "MC"},
        {label: "Other", value: "Other"}
      ];
      this.payeeTypeOptions = [
        {label: "Farmer", value: "Farmer"},
        {label: "MC", value: "MC"},
        {label: "Other", value: "Other"}
      ];
      this.purchaseTypeOptions = [
        {label: "Loose", value: "Loose"},
        {label: "Tender", value: "Tender"}
      ];
      this.tenderBagOptions = [
        {label: "Checks", value: "Checks"},
        {label: "Bags - Regular", value: "Bags_Regular"},
        {label: "Bags - Small", value: "Bags_Small"},
      ];
      this.new();
    }

  ngOnInit(): void {
    // On init
  }

  // Goto Main Ledger page
  gotoLedgerMain() {
    // Reset form if filled and cancelled
    this.ngZone.run(() => {
      this.router.navigate(["ledger"])
      .then((value) => {
        this.reset();
      });
    });
  }

  // Reset form
  reset() {
    this.LedgerEntry.reset();
    this.Tags = [];
  }

  // Add Form Controls from Model
  addFormControls() {
    this.LedgerEntry = this.fb.group({
      transactionDate: [{value: '', disabled: true}],
      type: 'D',
      product: 'Cotton',
      quality: '',
      weight: '',
      adjustedWeight: [{value: '', disabled: true}],
      reduceWeightBy: '',
      label: '',
      description: '',
      payee: '',
      payeeType: '',
      purchaseType: '',
      tenderBagType: '',
      tenderBagCount: '',
      ratePerUnit: '',
      rstId: '',
      receiptId: '',
      amount: [{value: '', disabled: true}],
      amountSource: '',
      reference: '',
      costCenter: '',
      tags: '',
      note: ''
    });
  }

  // New Transaction
  new() {
    this.detailsSubmitted = false;
    this.Tags = [];
    this.maxDate = new Date();
    this.addFormControls();
    this.onValueChanges();
  }

  // Value changes
  onValueChanges() {
    // Reset fields on purchase type change.
    this.LedgerEntry.get("purchaseType").valueChanges.subscribe((val) => {
      this.resetFieldsOnPurchaseTypeChange();
    });
    // Weight changes - Calculate adjusted weight
    this.LedgerEntry.get("weight").valueChanges.subscribe((val) => {
      this.adjustWeight(val);
    });
    this.LedgerEntry.get("reduceWeightBy").valueChanges.subscribe((val) => {
      this.adjustWeight(val);
    });
    this.LedgerEntry.get("tenderBagCount").valueChanges.subscribe((val) => {
      this.adjustWeight(val);
    });
    // Adjusted weight changes or rate per unit changes- Calculate amount
    this.LedgerEntry.get("ratePerUnit").valueChanges.subscribe((val) => {
      this.calculateAmount(val);
    });
    this.LedgerEntry.get("adjustedWeight").valueChanges.subscribe((val) => {
      this.calculateAmount(val);
    });
  }

  // Adjust weight
  adjustWeight(val) {
    const weight = this.LedgerEntry.get('weight').value || 0;
    const tenderBagCount = this.LedgerEntry.get('tenderBagCount').value || 0;
    const adjustedweight = this.LedgerEntry.get('adjustedWeight');
    const reduceWeightBy = this.LedgerEntry.get('reduceWeightBy').value || 0;
    let newAdjustedWeight = 0;
    if(+reduceWeightBy > 0) {
      newAdjustedWeight = (+weight - (tenderBagCount * reduceWeightBy));
    }
    else {
      newAdjustedWeight = +weight;
    }
    adjustedweight.setValue(newAdjustedWeight / 100); // Converting to quintals
  }

  // Calculate amount
  calculateAmount(val) {
    // Calculate total amount based on weight and rate per unit.
    const amount = this.LedgerEntry.get("amount");
    const ratePerUnit = this.LedgerEntry.get("ratePerUnit").value || 0;
    const adjustedWeight = this.LedgerEntry.get("adjustedWeight").value || 0;
    let totalAmount = 0;
    totalAmount = ratePerUnit * adjustedWeight;
    amount.setValue(totalAmount);
  }

  // Reset fields on purchase type change
  resetFieldsOnPurchaseTypeChange() {
    this.LedgerEntry.get("rstId").setValue("");
    this.LedgerEntry.get("receiptId").setValue("");
    this.LedgerEntry.get("adjustedWeight").setValue("");
    this.LedgerEntry.get("amount").setValue("");
    if(this.LedgerEntry.get('purchaseType').value.toUpperCase() === 'TENDER') {
      this.LedgerEntry.get('payeeType').disable();
    }
    else {
      this.LedgerEntry.get('payeeType').enable();
    }
  }

  // Save Ledger Entry
  save() {
    if(this.LedgerEntry.invalid) return;
    let details = this.LedgerEntry.getRawValue();
    this.convertObjectValuesToUpperCase(details);
    details.transactionDate = details.transactionDate.getTime();
    details.tags = this.Tags.join();
    this.service.add(details)
    .then(() => {
      console.log(`Transaction submitted`);
      this.detailsSubmitted = true;
    })
    .catch((error) => {
      console.log("Transaction failed");
    });
  }

  // To upper case
  toUpper(event) {
    if (event) {
      event.target.value = event.target.value.toUpperCase();
    }
  }

  // Convert values to upper case
  convertObjectValuesToUpperCase(values) {
    Object.keys(values).forEach((key) => {
      if (typeof values[key] !== "object") {
        values[key] = values[key].toString().toUpperCase();
      }
      else {
        this.convertObjectValuesToUpperCase(values[key]);
      }
      return values;
    });
  };

  // Update transaction
  update() {}

  // Void transaction
  void() {}

  // Tag events
  AddTag(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    // Add new Tag
    if ((value || '').trim()) {
      this.Tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  RemoveTag(Tag: string): void {
    const index = this.Tags.indexOf(Tag);

    if (index >= 0) {
      this.Tags.splice(index, 1);
    }
  }

}

// Label Value interface
interface labelValue {
  label: string,
  value: string
}