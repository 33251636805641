<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button mat-icon-button
        [routerLink]="['/']"><mat-icon>home</mat-icon></button>
        <span>Labour</span>
    </mat-toolbar-row>
</mat-toolbar>

<div class="container">
    <app-labour-form></app-labour-form>
    <app-labour-list></app-labour-list>
</div>
