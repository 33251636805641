import { Injectable } from '@angular/core';
import { Subject, Observable }    from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabourDataService {

  private ldId = new Subject<string>();
  id$: Observable<string>;

  constructor() { 
    this.id$ = this.ldId.asObservable();
  }

  captureId(id: string) {
    this.ldId.next(id);
  }
}
