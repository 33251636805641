import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService, UserService } from "../../services";

import { User } from "../../models/user";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  returnUrl: string;
  user: User;
  isAuthorized: boolean = false;
  //returningUser;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private userService: UserService
  ) {
    //this.returningUser = this.auth.returningUserValue();
    this.user = {
      id: "",
      lastLoggedOn: new Date(),
      authorized: false,
      data: {}
    };

    this.auth.checkLoginStatus()
      .then((value) => {
        if (value) {
          console.log("From login component, user is logged in", value);
          this.auth.currentUser()
            .then((user) => {
              if (user) {
                console.log("Saving user in local storage", user);
                localStorage.setItem("currentUser", JSON.stringify(user));
                // Navigate to the request url
                this.navigateUser();
              }
            })
            .catch((error) => {
              console.log("Error in login component during navigation", error);
            });
        }
      })
      .catch((error) => {
        console.log("User not logged in");
      });

  }

  ngOnInit(): void {
    // Get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  navigateUser() {
    if(!this.returnUrl) return;
    this.router.navigate([this.returnUrl]);
  }

  login() {
    // Login with Google by calling service
    this.auth.login()
      .then((result) => {
        //this.saveNewUser(result.user);
      })
      .then((data: any) => {
        this.router.navigate([this.returnUrl]);
      })
      .catch((error) => {
        // Do nothing, stay on Login page
      });
  }

  newUser(user) {
    console.log("User in newUser function is", user.providerData);
    this.user.id = user.uid;
    this.user.data.id = user.uid;
    this.user.data.userInfo = user.providerData[0];
  }

  async saveNewUser(user) {
    // Call the service to save new user
    if (!user) return;
    console.log("Check for user existence", user);
    if (await this.isExistingUser(user)) return;
    console.log("New user, will save", user);
    this.newUser(user);
    return this.userService.saveNewUser(this.user);
  }

  isExistingUser(user) {
    // Check for user existence
    return new Promise((resolve, reject) => {
      if (!user) reject(false);
      this.userService.getUserDetails(user.uid).subscribe((user: any) => {
        console.log("User in isExistingUser in LoginComponent", user);
        if (user && user.id !== undefined) resolve(true);
        resolve(false);
      }, (error) => { reject(false) });
    });
  }

  isUserAuthorized(user: User) {
    return this.userService.isAuthorized(user);
  }
}
