export const states = [
    { "code": "AN", "name": "ANDAMAN AND NICOBAR ISLANDS" },
    { "code": "AP", "name": "ANDHRA PRADESH" },
    { "code": "AR", "name": "ARUNACHAL PRADESH" },
    { "code": "AS", "name": "ASSAM" },
    { "code": "BR", "name": "BIHAR" },
    { "code": "CG", "name": "CHANDIGARH" },
    { "code": "CH", "name": "CHHATTISGARH" },
    { "code": "DH", "name": "DADRA AND NAGAR HAVELI" },
    { "code": "DD", "name": "DAMAN AND DIU" },
    { "code": "DL", "name": "DELHI" },
    { "code": "GA", "name": "GOA" },
    { "code": "GJ", "name": "GUJARAT" },
    { "code": "HR", "name": "HARYANA" },
    { "code": "HP", "name": "HIMACHAL PRADESH" },
    { "code": "JK", "name": "AMMU AND KASHMIR" },
    { "code": "JH", "name": "JHARKHAND" },
    { "code": "KA", "name": "KARNATAKA" },
    { "code": "KL", "name": "KERALA" },
    { "code": "LD", "name": "LAKSHADWEEP" },
    { "code": "MP", "name": "MADHYA PRADESH" },
    { "code": "MH", "name": "MAHARASHTRA" },
    { "code": "MN", "name": "MANIPUR" },
    { "code": "ML", "name": "MEGHALAYA" },
    { "code": "MZ", "name": "MIZORAM" },
    { "code": "NL", "name": "NAGALAND" },
    { "code": "OR", "name": "ODISHA" },
    { "code": "PY", "name": "PUDUCHERRY" },
    { "code": "PB", "name": "PUNJAB" },
    { "code": "RJ", "name": "RAJASTHAN" },
    { "code": "SK", "name": "SIKKIM" },
    { "code": "TN", "name": "TAMIL NADU" },
    { "code": "TS", "name": "TELANGANA" },
    { "code": "TR", "name": "TRIPURA" },
    { "code": "UK", "name": "UTTARAKHAND" },
    { "code": "UP", "name": "UTTAR PRADESH" },
    { "code": "WB", "name": "WEST BENGAL" }];