import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';

import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  customers: Object[];
  searchControl = new FormControl('');

  constructor(
    private custService: CustomerService
    ) { }

  ngOnInit(): void {
  }

  async searchCustomersByName(searchString) {
    this.customers = await this.custService.getCustomerListByNameSearch(searchString);
  }

  onSearchControlInput(event) {
    if(event.target.value.length < 2) return;
    this.searchCustomersByName(event.target.value);
  }

  onCustomerSelection(customer) {
    // console.log(customer);
  }

  displayValue(customer) {
    if(!customer) return;
    return customer.name;
  }

}
