<div class="ledger-options">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <button mat-icon-button [routerLink]="['/ledger']">
                <mat-icon>home</mat-icon>
            </button>
            <span>Ledger List</span>
            <span class="toolbar-empty-space"></span>
            <!-- <button mat-raised-button (click)="newTransaction()">New</button> -->
        </mat-toolbar-row>
    </mat-toolbar>
    <!-- <div class="new-fab-button">
        <button mat-fab color="primary" (click)="newTransaction()">
            <mat-icon>add</mat-icon>
        </button>
    </div> -->
</div>
<div class="container">
    <div class="ledger-content">
        <app-ledger-list></app-ledger-list>
    </div>
</div>