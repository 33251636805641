export const environment = {
    production: true,
    sgApiUrl: "https://service.santosh.app/",
    firebase: {
        apiKey: "AIzaSyDHxxPEZZPWp2KUy0dUeZUWr21Xg6TZHO8",
        authDomain: "santosh.app",
        databaseURL: "https://santosh-app-prod.asia-southeast1.firebasedatabase.app",
        projectId: "santosh-app",
        storageBucket: "santosh-app.appspot.com",
        messagingSenderId: "801425847629",
        appId: "1:801425847629:web:4faa7e08e4424baacf7304",
        measurementId: "G-L1T0Q8DK0M"
    }
};