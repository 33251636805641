import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";

import { Product } from "../../models/product";
import { Inventory } from "../../models/inventory";

import { InventoryService } from "../../services/inventory.service";
import { AddProductComponent } from '../add-product';
import { AddInventoryComponent } from '../add-inventory';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  newProduct: Product;
  newInventory: Inventory;

  constructor(
    private productDialog: MatDialog,
    private invDialog: MatDialog,
    private invService: InventoryService,
  ) { 
    this.newProduct = {};
    this.newInventory = {};
  }

  ngOnInit(): void {
  }

  openNewProductDialog() {
    const productDialogRef = this.productDialog.open(AddProductComponent, 
      {data: this.newProduct});
      productDialogRef.afterClosed().subscribe(result => {
        console.log("Result from dialog", result);
        console.log("And the product is", this.newProduct);
        if(result) this.addNewProduct();
        this.newProduct = {};
      });
  }

  openNewInventoryDialog() {
    const invDialogRef = this.invDialog.open(AddInventoryComponent, 
      {data: this.newInventory});
    invDialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log("Result from Add Inventory", this.newInventory);
        this.addNewInventory();
      }
      this.newInventory = {};
    });
  }

  addNewProduct() {
    this.newProduct.id = new Date().getTime().toString();
    console.log("Adding new product", this.newProduct);
    if(this.newProduct.name && this.newProduct.units) {
      this.invService.addProduct(this.newProduct);
    }
  }

  addNewInventory() {
    if(this.newInventory.pid && this.newInventory.quantity) {
      // Set required values and call api
      this.newInventory.lastActionQuantity = this.newInventory.quantity;
      this.invService.addToInventory(this.newInventory);
    }
  }
  
}