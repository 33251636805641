import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/compat/database';

import { UserService } from "../services/user.service";

@Injectable({
  providedIn: 'root'
})
export class LabourService {

  lbrRef: firebase.default.database.Reference;
  lbrList: string = "labour/data";
  logList: string = "labour/log";
  lbrData: firebase.default.database.Reference;
  logRef: firebase.default.database.Reference;

  localUserId: string;

  constructor(private db: AngularFireDatabase,
    private userService: UserService) { 
      this.lbrRef = db.database.ref("labour");
      this.lbrData = this.lbrRef.child("data");
      this.logRef = this.lbrRef.child("log");

      this.localUserId = this.userService.getLocalStorageOperator().uid;
    }

    // Get labour details list
    getLabourDetailsList() {
      return this.db.list(this.lbrList, ref => ref.orderByChild("createDateTime")).valueChanges();
    }

    // Get labour details
    getLabourDetails(id) {
      return this.lbrData.child(id).once("value");
    }

    // Add new labour entry
    addLabourDetails(labourDetails) {
      labourDetails.createDateTime = new Date().getTime();
      labourDetails.createOperatorId = this.localUserId;
      return this.lbrData.child(labourDetails.id).set(labourDetails)
      .then(() => {
        this.addLog({
          id: labourDetails.id, message: "Added new entry"
        });
      });
    }

    // Edit labour details
    editLabourDetails(labourDetails) {
      labourDetails.updateDateTime = new Date().getTime();
      labourDetails.updateOperatorId = this.localUserId;
      return this.lbrData.child(labourDetails.id).set(labourDetails)
      .then(() => {
        this.addLog({
          id: labourDetails.id, message: "Edited labour details", details: labourDetails
        });
      });
    }

    // Add log entry
    addLog(message: {}) {
      // Add log
      message["createDateTime"] = new Date().getTime();
      message["createOperatorId"] = this.localUserId;
      this.logRef.push(message);
    }
}
