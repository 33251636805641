<div class="sauda-dashboard">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <button mat-icon-button [routerLink]="['/']">
                <mat-icon>home</mat-icon>
            </button>
            <span>Sauda Dashboard</span>
            <span class="toolbar-empty-space"></span>
            <button mat-raised-button [routerLink]="['/newsauda']">
                New
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="container">
        <div class="sauda-list padding-1x">
            <p>Showing only open items. <button mat-button color="primary" (click)="getSaudaList()">Click here</button> to get all entries</p>
            <div *ngFor="let sauda of saudas$ | async"
                [ngClass]="sauda.status=='PENDING' ? 'pending' : sauda.status=='COMPLETE' || sauda.status=='CANCEL' ? 'complete' : ''">
                <mat-card appearance="outlined">
                    <mat-card-header>
                        <mat-card-title>{{ sauda.company }}</mat-card-title>
                        <mat-card-subtitle>{{ sauda.agent }}</mat-card-subtitle>
                        <mat-card-subtitle>{{ sauda.saudaDateTime | date }}</mat-card-subtitle>
                        <mat-card-subtitle>{{ sauda.status }}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p>Sauda for <span>
                                <span *ngIf="sauda.product==='B' else seedBlock">Bales</span>
                                <ng-template #seedBlock>
                                    <span *ngIf="sauda.product==='S' else otherBlock">Seed</span>
                                </ng-template>
                                <ng-template #otherBlock>
                                    <span>Other</span>
                                </ng-template>
                            </span> at {{ sauda.price | inr }}</p>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-flat-button color="primary" [routerLink]="['/sauda', sauda.id]">UPDATE</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</div>