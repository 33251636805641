import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { HostListener } from "@angular/core";
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { InvoiceService } from "../../../services/invoice.service";


@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  invList: Object[];
  invoicesSource: MatTableDataSource<any>;
  columnsToDisplay: string[] = [];
  displayedColumns: {}[];
  scrHeight: any;
  scrWidth: any = window.innerWidth;
  isMobileView: boolean = false;

  @HostListener('window:resize', ['$event'])
  scrWidthChanges(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if(this.scrWidth <= 600) {
      this.isMobileView = true;
    }
    else {
      this.isMobileView = false;
    }
    this.getColumnsToDisplay();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ngZone: NgZone,
    private router: Router,
    private invService: InvoiceService) {
    this.displayedColumns = [
      { id: "Date", hide: false, dWidth: 700 },
      { id: "ID", hide: false, dWidth: 1 },
      { id: "Customer", hide: false, dWidth: 1 },
      { id: "Product", hide: false, dWidth: 1000 },
      { id: "Weight", hide: false, dWidth: 1000 },
      { id: "Price", hide: false, dWidth: 1 },
    ];
    // this.getColumnsToDisplay();
    this.scrWidthChanges();
  }

  ngOnInit(): void {
    this.getInvoiceList();
  }

  ngAfterViewInit() {
    // this.invoicesSource.paginator = this.paginator;
  }

  newInvoice() {
    this.ngZone.run(() => {
      this.router.navigate(["/newinvoice"], { skipLocationChange: true });
    });
  }

  getInvoiceList() {
    this.invService.getInvoiceList().pipe(
      map((list) => { return list })
    )
      .subscribe((list) => {
        this.invList = list;
        this.invoicesSource = new MatTableDataSource(list);
        this.invoicesSource.paginator = this.paginator;
        // this.invoicesSource.sort = this.sort;
      });
  }

  getInvoice(id) {
    this.ngZone.run(() => {
      this.router.navigateByUrl(`/invoice/${id}`);
    });
  }

  getColumnsToDisplay() {
    this.columnsToDisplay = [];
    const columns = this.displayedColumns.filter(this.filterColumnsByScreenWidth);
    columns.forEach((column: { id: string, hide: boolean }) => {
      if (column.hide) return;
      this.columnsToDisplay.push(column.id);
    });
  }

  filterColumnsByScreenWidth(column) {
    return column.dWidth <= window.innerWidth;
  }

}
