import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import { UserService } from "../services/user.service";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  invIdRef: AngularFirestoreCollection<any>;

  invStore: AngularFirestoreCollection<any>;
  custStore: AngularFirestoreCollection<any>;
  paymentStore: AngularFirestoreCollection<any>;
  creditStore: AngularFirestoreCollection<any>;
  invStoreLog: AngularFirestoreCollection<any>;

  localUserId: string;

  constructor(private afs: AngularFirestore,
    private userService: UserService) {

    this.invStore = afs.collection<any>("invoices");
    this.custStore = afs.collection<any>("customers");
    this.paymentStore = afs.collection<any>("payments");
    this.creditStore = afs.collection<any>("invoiceCredits");
    this.invStoreLog = afs.collection<any>("invoicesLog");

    this.localUserId = this.userService.getLocalStorageOperator().uid;
  }

  // Add new invoice
  addInvoice(invoice) {
    invoice.createDateTime = new Date().getTime();
    invoice.createOperatorId = this.localUserId;
    const invRef = this.invStore.doc(invoice.invoiceNumber);
    
    //return newInvRef.set(invoice)
    // Firestore operation
    return invRef.set(invoice)
      .then((val) => {
        this.addLog({
          id: invoice.invoiceNumber, 
          message: "Added new invoice",
          data: invoice
        });
      })
      .then(() => {
        // Add or update customer
        this.addOrUpdateCustomer(invoice.customerAddress);
      })
      .then(() => {
        // Send text message if phone number is present
      })
      .catch((error) => {
        console.log(`Invoice creation failed ${error}`);
      });
  }

  // Get invoices
  getInvoiceList() {
    // return this.invStore.valueChanges({ idField: 'ID' });
    return this.afs.collection("invoices", ref => ref.orderBy("createDateTime", "desc").limit(100)).valueChanges("idField: 'ID'");
  }

  async getInvoice(id) {
    // Get the invoice with id
    if(!id) return;
    const snapshot = await this.invStore.ref.where("invoiceNumber", "==", id).get();
    if(snapshot.empty) return;
    return snapshot.docs[0].data();
  }

  // Add payment
  addPayment(payment) {
    // Add payment record to invoice
    // Add payment record to payment collection
    // Add log message 
    return this.invStore.doc(payment.invoiceNumber).collection("payments").add(payment)
    .then((pRef) => {
      payment.docRef = pRef.id;
      return this.paymentStore.add(payment);
    }) // Add to payments collection
    .then((psref) => {
      this.addLog({
        id: payment.invoiceNumber, message: `Added new payment ${psref.id}`
      });
    }) // Add to log messages
    .catch((error) => {
      console.log(`Error while adding new payment for ${payment.invoiceNumber} - ${error}`);
    }); // Handle error
  }

  // Get payments for the invoice
  getPayments(invoiceNumber) {
    // Get payments for the invoice 
    return this.invStore.doc(invoiceNumber).collection("payments", ref => ref.orderBy("transactionDate", "desc")).valueChanges()
  }

  // Credits
  addCredit(credit) {}

  // Add or update customer
  async addOrUpdateCustomer(customer) {
    if(!customer || customer.GSTNumber === "") return;
    // Check if customer exists - update
    // Create if new
    const cust = await this.custStore.doc(customer.GSTNumber).ref.get();
    if(!cust.exists) {
      // Create new customer
      await this.custStore.doc(customer.GSTNumber).set(customer);
    }
  }
  
  // Add log entry
  addLog(message: {}) {
    // Add log
    message["createDateTime"] = new Date().getTime();
    message["createOperatorId"] = this.localUserId;
    this.invStoreLog.add(message);
  }
}
