<h1 mat-dialog-title>{{data.action}} {{data.product.name}}</h1>
<div mat-dialog-content>
    <h2>Quantity in Inventory</h2>
    <h2>{{ data.product.quantity }} {{ data.product.units }}</h2>
    <div>
        <mat-form-field>
            <mat-label>How many {{ data.product.units }}?</mat-label>
            <input matInput [(ngModel)] = "data.actionQuantity">
            <span matSuffix>{{ data.product.units }}</span>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>Additional Note</mat-label>
            <textarea matInput [(ngModel)] = "data.note"></textarea>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{data.action}}</button>
</div>
