<div id="labour-details">
    <h1>Workers Details</h1>
    <form [formGroup]="labourDetails" *ngIf="detailsSubmitted!==true; else formSubmitted" >
        <mat-form-field class="input-field">
            <mat-label>Select Date</mat-label>
            <input matInput formControlName="date" required [matDatepicker]="date" 
            readonly (dateChange)="onDateChange('change', $event)">
            <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
            <mat-datepicker #date></mat-datepicker>
        </mat-form-field>

        <div formArrayName="groups" class="cat-group">

            <div *ngFor="let group of groups.controls; let g=index">
                <div [formGroupName]="g" class="sibling margin-b-1x">
                    <mat-form-field class="input-field">
                        <mat-label>Group Name</mat-label>
                        <input matInput formControlName="name">
                    </mat-form-field>
                    <div class="input-field">
                        <h3>
                            Total {{ labourDetails.value.groups[g].total }}
                        </h3>
                    </div>

                    <div formArrayName="categories" class="group cat-group">

                        <div *ngFor="let category of group['controls'].categories['controls']; let i=index"
                            class="group-item">

                            <div [formGroupName]="i" class="sibling">
                                <h2>Category {{i+1}}
                                    (<span
                                        class="highlight">{{ labourDetails.value.groups[g].categories[i].total }}</span>)
                                </h2>
                                <mat-form-field class="input-field">
                                    <mat-label>Category</mat-label>
                                    <mat-select formControlName="category" required>
                                        <mat-option *ngFor="let option of categoryOptions" [value]=option.value>
                                            {{ option.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="input-field"
                                    *ngIf="!labourDetails.value.groups[g].categories[i].hideCount">
                                    <mat-label>Count</mat-label>
                                    <input matInput formControlName="count">
                                </mat-form-field>

                                <mat-form-field class="input-field">
                                    <mat-label>Additional Note</mat-label>
                                    <textarea matInput formControlName="note"></textarea>
                                </mat-form-field>

                                <div formArrayName="subCategories" class="child group subcat-group">

                                    <div *ngFor="let subCategory of category.controls.subCategories.controls; let j=index"
                                        class="group-item">
                                        <div [formGroupName]="j" class="sibling">
                                            <h2>Sub Category {{j+1}}</h2>
                                            <mat-form-field class="input-field">
                                                <mat-label>Sub Category</mat-label>
                                                <mat-select formControlName="subCategory" required>
                                                    <mat-option *ngFor="let option of subCategoryOptions"
                                                        [value]=option.value>
                                                        {{ option.label }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field class="input-field">
                                                <mat-label>Count</mat-label>
                                                <input matInput formControlName="count" required>
                                            </mat-form-field>

                                            <mat-form-field class="input-field">
                                                <mat-label>Additional Note</mat-label>
                                                <textarea matInput formControlName="note"></textarea>
                                            </mat-form-field>

                                            <div class="right-aligned">
                                                <button mat-icon-button color="warn" (click)="removeSubCat(g, i, j)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <button mat-flat-button color="accent" (click)="addSubCategory(g, i)"
                                        class="group-item">Add
                                        Sub
                                        Category</button>

                                    <button mat-icon-button (click)="removeCat(g, i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <button mat-flat-button color="accent" (click)="addCategory(g)" class="group-btn">Add
                            Category
                        </button>
                        <button mat-icon-button color="primary" (click)="removeGroup(g)">
                            <mat-icon>delete</mat-icon>
                        </button>

                    </div>
                </div>
            </div>

            <button mat-flat-button color="accent" (click)="addGroup()" class="group-btn">
                Add Group
            </button>

            <button mat-flat-button color="primary" (click)="saveLabourDetails()" class="group-btn">
                Save({{ labourDetails.value.total }})
            </button>

        </div>

    </form>
    <ng-template #formSubmitted>
        <p>
            <span class="action-success">
                <mat-icon>check_circle</mat-icon> Labour details submitted
            </span>
        </p>
    </ng-template>
</div>
<!-- <p>Form Value: {{ labourDetails.value | json }}</p> -->