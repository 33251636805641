import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { InvoiceCredit } from 'src/app/models/invoice-credit';

@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.scss']
})
export class CreditComponent implements OnInit {

  constructor(
    public mdRef: MatDialogRef<CreditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvoiceCredit
  ) { 
    this.data.createDateTime = new Date().getTime().toString();
  }

  ngOnInit(): void {
  }

  cancel() {
    this.mdRef.close();
  }

}
