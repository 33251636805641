import { Component } from '@angular/core';

import { SaudaService } from 'src/app/services';
import { Sauda } from 'src/app/models/sauda';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-saudadashboard',
  templateUrl: './saudadashboard.component.html',
  styleUrls: ['./saudadashboard.component.scss']
})
export class SaudadashboardComponent {

  saudas$: Observable<Sauda[]>;

  constructor(private service: SaudaService) {
    // Get data
    this.getOpenSaudaList();
  }

  getSaudaList() {
    this.saudas$ = this.service.getSaudaList() as Observable<Sauda[]>;
  }

  getOpenSaudaList() {
    this.saudas$ = this.service.getOpenSaudaList() as Observable<Sauda[]>;
  }

}
