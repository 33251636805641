import { Defaults } from "../models/defaults";

export class NewDefaults {
    defaults: Defaults;

    constructor() {
        this.defaults = {
            createDateTime: new Date().getTime(),
            createOperatorName: "Santosh"
        };
    }
}

export const defaults = new NewDefaults();