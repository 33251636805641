import { Defaults } from "./defaults";

export interface Product extends Defaults {
    units?: string
}

export enum Units {
    Kilos = "Kilos",
    Quintals = "Quintals",
    Tonnes = "Tonnes",
    Litres = "Liters",
    Units = "Units",
    Other = "Other"
}