import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { Units } from "../../models/product";

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  //unitsEnum = Units;
  unitKeys = [];

  constructor(
    public mdRef: MatDialogRef<AddProductComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.unitKeys = Object.keys(Units);
  }

  ngOnInit(): void {
  }
  
  onNoClick(): void {
    this.mdRef.close();
  }

}
