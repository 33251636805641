<h1 mat-dialog-title>New Credit Note</h1>
<div class="mat-dialog-content">
    <div class="flex-box">
        <mat-form-field>
            <mat-label>Reason</mat-label>
            <input matInput required [(ngModel)]="data.reason">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Amount</mat-label>
            <input type="number" matInput required [(ngModel)]="data.amount">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Note</mat-label>
            <input matInput [(ngModel)]="data.note">
        </mat-form-field>
    </div>
</div>
<div class="mat-dialog-actions">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Submit</button>
</div>