<div class="manager-dashboard">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <button mat-icon-button [routerLink]="['/']">
                <mat-icon>home</mat-icon>
            </button>
            <span>Manager Dashboard</span>
            <span class="toolbar-empty-space"></span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="container padding-lr-1x padding-b-1x margin-tb-1x">
        <div class="search-box">
            <h3 mat-dialog-title>Customer search</h3>
                <mat-form-field class="customer-search-autocomplete" appearance="fill">
                    <mat-label>Customer</mat-label>
                    <input type="text" placeholder="Type customer name" aria-label="Customer" matInput [formControl]="searchControl"
                        [matAutocomplete]="auto" (input)="onSearchControlInput($event)">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)='onCustomerSelection($event.option.value)'
                    [displayWith]="displayValue">
                        <mat-option *ngFor="let customer of customers" [value]="customer">
                            {{customer.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
        </div>
    </div>
</div>