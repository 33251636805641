import { Component, OnInit, NgZone, HostListener } from '@angular/core';
import { Router } from "@angular/router";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { AddressForm } from './../../../models/address';
import { states } from './../../../models/states';

import { InvoiceService } from '../../../services/invoice.service';
import { SgService } from '../../../services/sg.service';

@Component({
  selector: 'app-newinvoice',
  templateUrl: './newinvoice.component.html',
  styleUrls: ['./newinvoice.component.scss']
})
export class NewinvoiceComponent implements OnInit {

  invoiceFormGroup: UntypedFormGroup;
  customerAddressForm: UntypedFormGroup;
  deliveryAddressForm: UntypedFormGroup;
  states = states;
  Products: Object[];
  paymentTypes: Object[];
  invoiceSubmitted: boolean = false;
  sameCustomerAddress: boolean = false;
  candyWeight = 355.6;

  constructor(private ngZone: NgZone,
    private router: Router,
    private fb: UntypedFormBuilder,
    private invService: InvoiceService,
    private sgService: SgService) {
    this.customerAddressForm = new AddressForm().AddressFormGroup;
    this.deliveryAddressForm = new AddressForm().AddressFormGroup;
    this.customerAddressForm.addControl("GSTNumber", new UntypedFormControl(""));
    this.customerAddressForm.addControl("GSTStateCode", new UntypedFormControl(""));
    this.deliveryAddressForm.addControl("GSTNumber", new UntypedFormControl(""));
    this.deliveryAddressForm.addControl("GSTStateCode", new UntypedFormControl(""));
    this.Products = [
      {
        label: "Cotton Bales", value: "B"
      },
      {
        label: "Cotton Seed", value: "S"
      }];
    this.paymentTypes = [
      {
        label: "On Delivery", value: "D"
      },
      {
        label: "Spot Payment", value: "S"
      }];
    this.addFormControls();
  }

  ngOnInit(): void {
    this.setInitialInvoiceDetails();

    // Handle value changes
    this.onValueChanges();
  }

  // Add form controls
  addFormControls() {
    this.invoiceFormGroup = this.fb.group({
      invoiceNumber: [''],
      tallyRefId: [''],
      wayBillNumber: [''],
      eBillId: [''],
      paymentType: [''],
      lrNumber: [''],
      incoTerms: [''],
      transportMode: ['TRUCK'],
      vehicleNumber: [''],
      product: [''],
      lotNumbers: [''],
      pressMark: [''],
      prNumber: [''],
      ratePerCandy: [''],
      ratePerKG: [''],
      baleCount: [''],
      gunnyBagCount: [''],
      totalNetKGS: [''],
      HSN: [''],
      CGST: [''],
      SGST: [''],
      IGST: [''],
      price: [''],
      miscellaneousPrice: [''],
      advanceAmount: [''],
      totalPrice: [''],
      agentName: [''],
      saleConfirmation: [''],
      createDateTime: [''],
      notes: [''],
      customerAddress: this.customerAddressForm,
      deliveryAddress: this.deliveryAddressForm,
      additionalItems: this.fb.array([]),
      additionalItemsPrice: ['']
    });
  };

  addAdditionalItem() {
    const items = this.invoiceFormGroup.controls.additionalItems as UntypedFormArray;
    items.push(this.fb.group({
      description: [''],
      HSN: [''],
      count: [''],
      price: [''],
    }));
  }

  removeAdditionalItem(event, idx) {
    const items = this.invoiceFormGroup.controls.additionalItems as UntypedFormArray;
    items.removeAt(idx);
    this.additionalItemPriceChange(event, idx);
  }

  setInitialInvoiceDetails() {
    this.invoiceFormGroup.patchValue({
      "createDateTime": Date.now()
    });

    this.getInvoiceId()
      .then((id) => {
        this.invoiceFormGroup.patchValue({
          "invoiceNumber": id
        });
      })
      .catch((error) => {
        this.invoiceFormGroup.patchValue({
          "invoiceNumber": Date.now()
        });
      });

    // Disable HSN
    this.invoiceFormGroup.get("HSN").disable();
    // Disable Rate Per KG
    this.invoiceFormGroup.get("ratePerKG").disable();
    // Disable Press Mark
    this.invoiceFormGroup.get("pressMark").disable();
    // Disable PR Number
    this.invoiceFormGroup.get("prNumber").disable();

    // Set press mark
    this.setPressMark();
  }

  onValueChanges() {
    // Product value change
    this.invoiceFormGroup.get("product").valueChanges.subscribe((val) => {
      this.setHSN(val);
      this.setBaleOrSeedFields(val);
    });

    // Customer state value change
    this.invoiceFormGroup.get("customerAddress").get("state").valueChanges.subscribe((val) => {
      this.calculatePrice();
    });

    // Calculate price changes
    this.onPriceAndWeightChanges();
  }

  additionalItemPriceChange(event, idx) {
    const addlItems = this.invoiceFormGroup.controls.additionalItems.value;
    let newPrice = addlItems.reduce((acc, cur, idx) => {
      return (parseFloat(acc || 0) + parseFloat(cur.price || 0));
    }, 0);
    this.invoiceFormGroup.get("additionalItemsPrice").setValue(newPrice);
  }

  // Set HSN code based on product selection
  setHSN(val) {
    const HSN = this.invoiceFormGroup.get("HSN");
    if (val === "B") { // Cotton bales
      HSN.setValue("5201");
    }
    else if (val === "S") { // Cotton seed
      HSN.setValue("1207");
    }
    else {
      HSN.setValue("");
      HSN.enable();
    }
  }

  // Enable or Disable fields based on product
  setBaleOrSeedFields(value) {
    if (!value) return;
    this.resetFieldsOnProductChange();
    if (value == "S") {
      // Disable fields
      const fields = ["ratePerCandy", "lotNumbers", "baleCount", "prNumber"];
      this.enableOrDisableFields("d", fields);
      // Enable fields
      this.invoiceFormGroup.get("ratePerKG").enable();
    } // Cotton seed
    else if (value == "B") { // Cotton bales
      const fields = ["ratePerCandy", "lotNumbers", "baleCount", "prNumber"];
      this.enableOrDisableFields("e", fields);
      // Disable fields
      const disableFields = ['ratePerKG', 'gunnyBagCount'];
      this.enableOrDisableFields("d", disableFields);
    }
  }

  resetFieldsOnProductChange() {
    this.invoiceFormGroup.get("ratePerCandy").setValue("");
    this.invoiceFormGroup.get("lotNumbers").setValue("");
    this.invoiceFormGroup.get("prNumber").setValue("");
    this.invoiceFormGroup.get("baleCount").setValue("");
    this.invoiceFormGroup.get("gunnyBagCount").setValue("");
    this.invoiceFormGroup.get("ratePerKG").setValue("");
    this.invoiceFormGroup.get("price").setValue("");
    this.invoiceFormGroup.get("miscellaneousPrice").setValue("");
    this.invoiceFormGroup.get("CGST").setValue("");
    this.invoiceFormGroup.get("SGST").setValue("");
    this.invoiceFormGroup.get("IGST").setValue("");
    this.invoiceFormGroup.get("advanceAmount").setValue("");
    this.invoiceFormGroup.get("totalPrice").setValue("");
  }

  enableOrDisableFields(action: string, fields: string[]) {
    if (!action) return;
    if (action == "e") {
      for (const field of fields) {
        this.invoiceFormGroup.get(field).enable();
      }
    }
    else {
      for (const field of fields) {
        this.invoiceFormGroup.get(field).disable();
      }
    }
  }

  // Set Press Mark
  setPressMark() {
    const prefix = "AP/SCGP/";
    const year = new Date().getFullYear();
    const pm = prefix + year;
    this.invoiceFormGroup.get("pressMark").setValue(pm);
  }

  // Calculate GST values based on Customer state value

  calculateGSTAndTotalPrice() {
    const state = this.invoiceFormGroup.get("customerAddress").get("state").value;
    if (!state) return;
    let price = this.invoiceFormGroup.get("price").value;
    let miscellaneousPrice = this.invoiceFormGroup.get("miscellaneousPrice").value;
    let advanceAmount = this.invoiceFormGroup.get("advanceAmount").value;
    let addtionalItemsPrice = this.invoiceFormGroup.get("additionalItemsPrice").value;
    let combinedPrice = price + (addtionalItemsPrice || 0) + (miscellaneousPrice || 0);
    if (price <= 0) return;
    const CGST = this.invoiceFormGroup.get("CGST");
    const SGST = this.invoiceFormGroup.get("SGST");
    const IGST = this.invoiceFormGroup.get("IGST");
    const CGSTFactor = 2.5;
    const SGSTFactor = 2.5;
    const IGSTFactor = 5;
    let CGSTValue = 0;
    let SGSTValue = 0;
    let IGSTValue = 0;
    let GSTValue = 0;
    let totalPrice = 0;
    let total = this.invoiceFormGroup.get("totalPrice");

    if (state === "AP") { // State is Andhra Pradesh
      // Calculate CGST and SGST
      CGSTValue = (combinedPrice * CGSTFactor) / 100;
      SGSTValue = (combinedPrice * SGSTFactor) / 100;
      GSTValue = CGSTValue + SGSTValue;
    }
    else {
      IGSTValue = (combinedPrice * IGSTFactor) / 100;
      GSTValue = IGSTValue;
    }

    totalPrice = combinedPrice + (advanceAmount || 0) + GSTValue;
    CGST.setValue(CGSTValue);
    SGST.setValue(SGSTValue);
    IGST.setValue(IGSTValue);
    total.setValue(totalPrice);
  }

  // Price and Weight changes

  onPriceAndWeightChanges() {

    this.invoiceFormGroup.get("ratePerCandy").valueChanges.subscribe((val) => {
      const candyRate = Number(val);
      if (candyRate <= 0) return;
      const KGRate = candyRate / this.candyWeight;
      this.invoiceFormGroup.get("ratePerKG").setValue(KGRate);
    });

    this.invoiceFormGroup.get("ratePerKG").valueChanges.subscribe((val) => {
      this.calculatePrice();
    });
    this.invoiceFormGroup.get("totalNetKGS").valueChanges.subscribe((val) => {
      this.calculatePrice();
    });
    this.invoiceFormGroup.get("miscellaneousPrice").valueChanges.subscribe((val) => {
      this.calculatePrice();
    });
    this.invoiceFormGroup.get("advanceAmount").valueChanges.subscribe((val) => {
      this.calculatePrice();
    });
    this.invoiceFormGroup.get("additionalItemsPrice").valueChanges.subscribe((val) => {
      this.calculatePrice();
    });
  }

  calculatePrice() {
    const rate = Number(this.invoiceFormGroup.get("ratePerKG").value);
    const weight = Number(this.invoiceFormGroup.get("totalNetKGS").value);
    const product = this.invoiceFormGroup.get("product").value;
    if (!product) return;
    if (!(rate > 0 && weight > 0)) return;

    const price = rate * weight;
    this.invoiceFormGroup.get("price").setValue(price);

    // Calculate GST
    this.calculateGSTAndTotalPrice();
  }

  gotoInvoiceMain() {
    // Reset form if filled and cancelled
    this.ngZone.run(() => {
      this.router.navigate(["invoice"])
        .then((value) => {
          this.reset();
        });
    });
  };

  copyCustomerAddress(value) {
    if (value) {
      this.invoiceFormGroup.get("deliveryAddress").setValue(this.invoiceFormGroup.get("customerAddress").value);
    }
    else {
      this.invoiceFormGroup.get("deliveryAddress").reset();
    }
  }

  toUpper(event) {
    if (event) {
      event.target.value = event.target.value.toUpperCase();
    }
  }

  save() {
    if (this.invoiceFormGroup.valid) {
      let values = this.invoiceFormGroup.getRawValue();
      this.convertObjectValuesToUpperCase(values);
      this.invService.addInvoice(values)
        .then(() => {
          // Success
          this.invoiceSubmitted = true;
        })
        .then(() => {
          // Send text
          if (true) return;
          this.sgService.sendInvoiceText(values.invoiceNumber)
            .subscribe((val) => { }, (err) => {
              this.invService.addLog({ message: `Text not sent for ${values.invoiceNumber} - ${err}` });
            });
        });
    }
  };

  convertObjectValuesToUpperCase(values) {
    Object.keys(values).forEach((key) => {
      if (values[key] && typeof values[key] !== "object") {
        values[key] = values[key].toString().toUpperCase();
      }
      else {
        this.convertObjectValuesToUpperCase(values[key]);
      }
      return values;
    });
  };

  reset() {
    this.invoiceFormGroup.reset();
  };

  download(id) {
    this.sgService.downloadInvoicePDF(id)
      .then((data) => {
        data.subscribe((response) => {
          let blob = new Blob([response], { type: 'application/pdf' });
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = `sg-invoice-${id}.pdf`;
          a.click();
          URL.revokeObjectURL(objectUrl);
        });
      })
      .catch((error) => {
        console.log(`Error downloading pdf for ${id}`);
      });
  };

  getInvoiceId() {
    return new Promise((resolve, reject) => {
      this.sgService.getUniqueId("inv")
        .then((idData) => {
          idData.subscribe((data) => {
            resolve(data["id"]);
          }, (error) => {
            reject("0");
          });
        })
        .catch((error) => {
          reject("0");
        });
    });
  };

  trackByFn(index, item) {
    return index;
  }
}
