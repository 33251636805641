<mat-toolbar color="primary">
    <mat-toolbar-row>
        <span>New Invoice</span>
        <span class="toolbar-empty-space"></span>
        <!-- <button mat-raised-button (click)="newTransaction()">New</button> -->
    </mat-toolbar-row>
</mat-toolbar>
<div class="container padding-lr-1x margin-tb-1x">

    <div class="invoice-form-box">
        <form [formGroup]="invoiceFormGroup" *ngIf="invoiceSubmitted!==true; else formSubmitted">
            <h3>
                Invoice Date: <span><strong>{{ invoiceFormGroup.value.createDateTime | date: 'medium' }}</strong></span>
            </h3>
            <!-- <h1>Invoice Number: <span><strong>{{ invoiceFormGroup.value.invoiceNumber }}</strong></span></h1> -->
            <div>
                <mat-form-field>
                    <mat-label>Tally Ref Id</mat-label>
                    <input matInput formControlName="tallyRefId" (input)="toUpper($event)">
                </mat-form-field>
            </div>
            <div class="two-column-grid margin-b-1x">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>Enter Customer Address</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div formGroupName="customerAddress">
                            <div class="flex-box">
                                <mat-form-field>
                                    <mat-label>Name</mat-label>
                                    <input matInput formControlName="name" required (input)="toUpper($event)">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Address Line 1</mat-label>
                                    <input matInput formControlName="addressLine1" required (input)="toUpper($event)">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Address Line 2</mat-label>
                                    <input matInput formControlName="addressLine2" (input)="toUpper($event)">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>City</mat-label>
                                    <input matInput formControlName="city" required (input)="toUpper($event)">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Pincode</mat-label>
                                    <input matInput formControlName="pincode" required pattern="[0-9]{6}" minlength="6"
                                        maxlength="6">
                                    <mat-error
                                        *ngIf="this.invoiceFormGroup.get('customerAddress').get('pincode').invalid">
                                        Please enter a valid PIN code
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Select State</mat-label>
                                    <mat-select formControlName="state" required>
                                        <mat-option *ngFor="let state of states" [value]=state.code>
                                            {{ state.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Phone Number</mat-label>
                                    <input matInput formControlName="phoneNumber" pattern="[0-9]{10}" minlength="10"
                                        maxlength="10" required>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>GST Number</mat-label>
                                    <input matInput formControlName="GSTNumber" (input)="toUpper($event)">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>GST State Code</mat-label>
                                    <input matInput formControlName="GSTStateCode">
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card>
                    <mat-card-content>
                        <div formGroupName="deliveryAddress">
                            <mat-checkbox labelPosition="before" class="address-header-checkbox"
                                [checked]="sameCustomerAddress" (change)="copyCustomerAddress($event.checked)">
                                <span class="address-header-checkbox-label">Delivery same as Customer
                                    Address?</span>
                            </mat-checkbox>
                            <div class="flex-box">
                                <mat-form-field>
                                    <mat-label>Name</mat-label>
                                    <input matInput formControlName="name" required (input)="toUpper($event)">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Address Line 1</mat-label>
                                    <input matInput formControlName="addressLine1" required (input)="toUpper($event)">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Address Line 2</mat-label>
                                    <input matInput formControlName="addressLine2" (input)="toUpper($event)">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>City</mat-label>
                                    <input matInput formControlName="city" required (input)="toUpper($event)">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Pincode</mat-label>
                                    <input matInput formControlName="pincode" required pattern="[0-9]{6}" minlength="6"
                                        maxlength="6">
                                    <mat-error
                                        *ngIf="this.invoiceFormGroup.get('deliveryAddress').get('pincode').invalid">
                                        Please enter a valid PIN code
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Select State</mat-label>
                                    <mat-select formControlName="state" required>
                                        <mat-option *ngFor="let state of states" [value]=state.code>
                                            {{ state.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Phone Number</mat-label>
                                    <input matInput formControlName="phoneNumber" pattern="[0-9]{10}" minlength="10"
                                        maxlength="10">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>GST Number</mat-label>
                                    <input matInput formControlName="GSTNumber" (input)="toUpper($event)">
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>GST State Code</mat-label>
                                    <input matInput formControlName="GSTStateCode">
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <mat-divider></mat-divider>
            <div class="two-column-grid margin-tb-1x">
                <mat-card>
                    <mat-card-content>
                        <div class="flex-box">
                            <mat-form-field>
                                <mat-label>Way Bill Number</mat-label>
                                <input type="number" matInput formControlName="wayBillNumber" required>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>LR Number</mat-label>
                                <input matInput formControlName="lrNumber" required>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Inco Terms</mat-label>
                                <input matInput formControlName="incoTerms" required (input)="toUpper($event)">
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-content>
                        <div class="flex-box">
                            <mat-form-field>
                                <mat-label>Type Of Payment</mat-label>
                                <mat-select formControlName="paymentType" required>
                                    <mat-option *ngFor="let type of paymentTypes" [value]=type.value>
                                        {{ type.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Transport Mode</mat-label>
                                <input matInput formControlName="transportMode" required>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Vehicle Number</mat-label>
                                <input matInput formControlName="vehicleNumber" required (input)="toUpper($event)">
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <mat-divider></mat-divider>
            <div class="two-column-grid margin-tb-1x">
                <mat-card>
                    <mat-card-content>
                        <div class="flex-box">
                            <mat-form-field>
                                <mat-label>Agent Name</mat-label>
                                <input matInput formControlName="agentName" (input)="toUpper($event)">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Sale Confirmation Id</mat-label>
                                <input matInput formControlName="saleConfirmation" (input)="toUpper($event)">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Product</mat-label>
                                <mat-select formControlName="product" required>
                                    <mat-option *ngFor="let Product of Products" [value]=Product.value>
                                        {{ Product.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>HSN Code</mat-label>
                                <input matInput formControlName="HSN">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Press Mark</mat-label>
                                <input matInput formControlName="pressMark">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>PR Number</mat-label>
                                <input matInput formControlName="prNumber" (input)="toUpper($event)">
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-content>
                        <div class="flex-box">
                            <mat-form-field>
                                <mat-label>Lot Numbers(Comma separated)</mat-label>
                                <input matInput formControlName="lotNumbers">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Rate Per Candy</mat-label>
                                <input type="number" matInput formControlName="ratePerCandy">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Rate Per KG</mat-label>
                                <input type="number" matInput formControlName="ratePerKG">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Total Net KGS</mat-label>
                                <input type="number" matInput formControlName="totalNetKGS">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Number Of Bales</mat-label>
                                <input type="number" matInput formControlName="baleCount">
                            </mat-form-field>
                            <!-- <mat-form-field>
                            <mat-label>Advance Amount</mat-label>
                            <input type="number" matInput formControlName="advanceAmount">
                        </mat-form-field> -->
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <mat-divider></mat-divider>
            <div>
                <div>
                    <h2>Additional Items</h2>
                    <div>
                        <div formArrayName="additionalItems"
                            *ngFor="let item of invoiceFormGroup.controls.additionalItems?.value; let i=index trackBy: trackByFn">
                            <div [formGroupName]="i" class="margin-tb-1x">
                                <mat-card appearance="outlined" class="additional-item">
                                    <mat-form-field>
                                        <mat-label>Description</mat-label>
                                        <input matInput formControlName="description">
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>HSN</mat-label>
                                        <input matInput formControlName="HSN">
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>Count</mat-label>
                                        <input type="number" matInput formControlName="count">
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>Price</mat-label>
                                        <input type="number" matInput formControlName="price"
                                            (input)="additionalItemPriceChange($event, i)">
                                    </mat-form-field>
                                    <button mat-icon-button color="accent" (click)="removeAdditionalItem($event, i)">
                                        <mat-icon>remove_circle_outline</mat-icon>
                                    </button>
                                </mat-card>
                            </div>
                        </div>
                        <button mat-icon-button color="accent" (click)="addAdditionalItem()">
                            <mat-icon>add_circle_outline</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="margin-tb-1x">
                <mat-card appearance="outlined">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Notes</mat-label>
                        <textarea matInput formControlName="notes" placeholder="Enter any note"
                            maxlength="120"></textarea>
                    </mat-form-field>
                </mat-card>
            </div>
            <div class="two-column-grid margin-tb-1x">
                <div>
                    <div>
                        <mat-form-field>
                            <mat-label>Advance Amount</mat-label>
                            <input type="number" matInput formControlName="advanceAmount">
                        </mat-form-field>
                    </div>
                    <h3>Price: <span><strong>{{ invoiceFormGroup.value.price | inr }}</strong></span></h3>
                    <!-- <h3>Miscellaneous Charges:
                        <span><strong>{{ invoiceFormGroup.value.miscellaneousPrice | inr }}</strong></span>
                    </h3> -->
                    <h3>Additional Items:
                        <span><strong>{{ invoiceFormGroup.value.additionalItemsPrice | inr }}</strong></span>
                    </h3>
                    <h3>SGST 2.5%: <span><strong>{{ invoiceFormGroup.value.SGST | inr }}</strong></span></h3>
                    <h3>CGST 2.5%: <span><strong>{{ invoiceFormGroup.value.CGST | inr }}</strong></span></h3>
                    <h3>IGST 5%: <span><strong>{{ invoiceFormGroup.value.IGST | inr }}</strong></span></h3>
                    <h3>Total Price: <span><strong>{{ invoiceFormGroup.value.totalPrice | inr }}</strong></span></h3>
                </div>
            </div>
            <div class="button-section centered padding-b-1x">
                <button mat-flat-button color="accent" (click)="gotoInvoiceMain()">Cancel</button>
                <button mat-flat-button color="accent" (click)="reset()">Reset</button>
                <button mat-flat-button color="primary" (click)="save()">Add</button>
            </div>
        </form>

        <ng-template #formSubmitted>
            <div class="action-success centered">
                <div>
                    <mat-icon>check_circle_outline</mat-icon>
                </div>
                <p>Success</p>
                <p>
                    Invoice {{ invoiceFormGroup.value.invoiceNumber }} created
                </p>
                <p>
                    Customer - {{ invoiceFormGroup.value.customerAddress.name | uppercase }}
                </p>
                <p>
                    Product - {{ invoiceFormGroup.value.product }}
                </p>
                <p>
                    Total price - {{ invoiceFormGroup.value.totalPrice | inr }}
                </p>
                <div class="button-section">
                    <button mat-flat-button color="primary"
                        (click)="download(invoiceFormGroup.value.invoiceNumber)">Download Invoice</button>
                    <button mat-raised-button color="accent" (click)="gotoInvoiceMain()">Invoice Home</button>
                </div>
            </div>
        </ng-template>
    </div>

</div>