<div class="sauda-dashboard">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <button mat-icon-button [routerLink]="['/sauda']">
                <mat-icon>home</mat-icon>
            </button>
            <span>New Sauda Entry</span>
            <span class="toolbar-empty-space"></span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="container padding-lr-1x margin-tb-1x">
        <form class="flex-box" [formGroup]="saudaForm" *ngIf="saudaSubmitted!==true; else formSubmitted">
            <mat-form-field>
                <mat-label>Reference Id</mat-label>
                <input matInput formControlName="refId">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Sauda Date</mat-label>
                <input matInput formControlName="saudaDateTime" required readonly [matDatepicker]="saudaDate">
                <mat-datepicker-toggle matSuffix [for]="saudaDate"></mat-datepicker-toggle>
                <mat-datepicker #saudaDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Product</mat-label>
                <mat-select formControlName="product" required>
                    <mat-option *ngFor="let Product of Products" [value]=Product.value>
                        {{ Product.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- <mat-form-field>
                <mat-label>Quality</mat-label>
                <input matInput formControlName="quality">
            </mat-form-field> -->
            <mat-form-field>
                <mat-label>Agent</mat-label>
                <input matInput formControlName="agent">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Company</mat-label>
                <input matInput formControlName="company">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Price</mat-label>
                <input type="number" matInput formControlName="price">
                <span matTextPrefix>&#8377;</span>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Note</mat-label>
                <textarea matInput formControlName="note" placeholder="Enter any note" maxlength="200"></textarea>
            </mat-form-field>
            <div class="button-section centered padding-b-1x">
                <button mat-flat-button color="accent" (click)="reset()">Reset</button>
                <button mat-flat-button color="primary" (click)="add()">Add</button>
            </div>
        </form>
        <ng-template #formSubmitted>
            <div class="action-success centered">
                <div>
                    <mat-icon>check_circle_outline</mat-icon>
                </div>
                <p>Success</p>
                <div class="button-section">
                    <button mat-flat-button color="primary" [routerLink]="['/sauda']">Dashboard</button>
                </div>
            </div>
        </ng-template>
    </div>
</div>