import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";

import { InvoiceService } from "../../../services/invoice.service";
import { SgService } from '../../../services/sg.service';

import { InvoicePayment } from 'src/app/models/invoice-payment';
import { InvoiceCredit } from 'src/app/models/invoice-credit';
import { PaymentDialogComponent } from '..';
import { CreditComponent } from '..';

@Component({
  selector: 'app-reviewinvoice',
  templateUrl: './reviewinvoice.component.html',
  styleUrls: ['./reviewinvoice.component.scss']
})
export class ReviewinvoiceComponent implements OnInit {
  invoice: any;
  id: string;
  additionalItemsColumns: string[] = [
    'HSN', 'Count', 'Description', 'Price'
  ];
  newPayment: InvoicePayment;
  newCredit: InvoiceCredit;
  paymentsColumns: string[] = [
    'Date', 'Amount', 'Reference'
  ];

  constructor(private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private paymentDialog: MatDialog,
    private creditDialog: MatDialog,
    private invService: InvoiceService,
    private sgService: SgService) {
    this.invoice = {
      customerAddress: {},
      deliveryAddress: {},
      additionalItems: [{}],
      payments: [{}]
    };
    this.newPayment = {};
    this.newCredit = {};
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: any) => {
      this.id = params.get("id");
      this.getInvoice(this.id);
    });
  }

  goHome() {
    this.ngZone.run(() => {
      this.router.navigate(["/invoice"]);
    });
  }

  getInvoice(id) {
    if (!id) return;
    this.invService.getInvoice(id)
      .then((invoice) => {
        this.invoice = invoice;
        // console.log(this.invoice);
        return this.invoice;
      })
      .then((invoice) => {
        // Get payments
        return;
        this.invService.getPayments(invoice.invoiceNumber).subscribe((payments) => {
          this.invoice.payments = payments;
        });
      })
      .catch((error) => {
        console.error(`Error while getting invoice details - ${error}`);
      });
  }

  download(id) {
    this.sgService.downloadInvoicePDF(id)
      .then((data) => {
        data.subscribe((response) => {
          let blob = new Blob([response], { type: 'application/pdf' });
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = `sg-invoice-${id}.pdf`;
          a.click();
          URL.revokeObjectURL(objectUrl);
        });
      })
      .catch((error) => {
        console.log(`Error downloading pdf for ${id}`);
      });
  };

  // Payments

  openPaymentDialog() {
    const paymentDialogRef = this.paymentDialog.open(PaymentDialogComponent, { data: this.newPayment });
    paymentDialogRef.afterClosed().subscribe(result => {
      //  console.log(`Result from dialog ${result}`);
      if (result) this.createNewPayment();
      this.newPayment = {};
    });
  }

  createNewPayment() {
    this.newPayment.invoiceNumber = this.invoice.invoiceNumber;
    this.newPayment.transactionDate = Date.parse(this.newPayment.transactionDate).toString();
    //  console.log(this.newPayment);
    this.invService.addPayment(this.newPayment);
  }

  // Credits
  openCreditDialog () {
    const creditDialogRef = this.creditDialog.open(CreditComponent, {data: this.newCredit});
    creditDialogRef.afterClosed().subscribe((result) => {
      // Add new credit
      this.newCredit = {}; // Reset credit instance
    });
  }

  createNewCredit() {
    this.newCredit.invoiceNumber = this.invoice.invoiceNumber;
  }

}
