import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { InventoryService } from "../../services/inventory.service";
import { Product } from "../../models/product";

@Component({
  selector: 'app-add-inventory',
  templateUrl: './add-inventory.component.html',
  styleUrls: ['./add-inventory.component.scss']
})
export class AddInventoryComponent implements OnInit {

  units: string = "";
  products: Product[] = [];

  constructor(
    public mdRef: MatDialogRef<AddInventoryComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private invService: InventoryService
  ) { 
    
    this.data.updateDateTime = new Date().getTime().toString();
    this.data.lastAction = "Add";

    this.invService.getAllProducts().subscribe(list => {
      //this.products = list;
      if(list) {
        this.invService.getInventoryList().subscribe(invList => {
          if(invList) {
            //console.log("Inv list is", invList);
            list.forEach((product: any) => {
              if(!invList.some(item => item["pid"] === product.id)) {
                this.products.push(product);
              }
            });
          }
        });
      }
    });
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.mdRef.close();
  }

  getProductInfo(id) {
    this.invService.getProductDetails(id).subscribe((product) => {
      this.units = product["units"];
      this.data.units = this.units;
    });
  }

}
