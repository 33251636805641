import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";

import { InventoryService } from "../../services/inventory.service";
import { UserService } from "../../services/user.service";
import { ModifyQuantityComponent } from "../modify-quantity";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  products$: Observable<any>;

  constructor(
    private invService: InventoryService,
    private userService: UserService,
    private qtyDialog: MatDialog
  ) {
    this.buildInventoryList();
  }

  ngOnInit(): void { }

  buildInventoryList() {
    this.products$ = this.invService.getProductsList().pipe(
      map((productsList) => {
        // Get inventory item for each product
        productsList.map((product: any) => {
          this.invService.getInventoryDetails(product.id)
          .subscribe((invItem: any) => {
            if(invItem === null) return;
            // Update last action
            if (invItem.lastAction) {
              if (invItem.lastAction === "Add") {
                invItem.lastAction = "Added";
                invItem.class = "action-added";
              }
              else {
                invItem.lastAction = "Used";
                invItem.class = "action-used";
              }
            }
            // Merge Product and Inventory item
            product = Object.assign(product, invItem);
          }, (error) => error);
        })
        return productsList;
      })
    );
  }

  openQtyDialog(action, product) {
    const qtyDialogRef = this.qtyDialog.open(ModifyQuantityComponent,
      { data: { action: action, product: product } });
    qtyDialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Take action
        this.updateInventory(product, result);
      }
      // Additional steps here
    });
  }

  updateInventory(product, result) {
    if (result.action === "Add") {
      product.quantity = parseFloat(product.quantity) + parseFloat(result.actionQuantity);
    }
    else if (result.action === "Use") {
      product.quantity -= result.actionQuantity;
    }
    product.previousQuantity = product.quantity;
    product.lastAction = result.action;
    product.lastActionQuantity = result.actionQuantity;
    product.note = result.note || "";
    product.updateOperatorId = this.userService.getLocalStorageOperator().uid;
    this.invService.updateQuantity(result.action, product);
  }

}
