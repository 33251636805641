<!-- <div class="container">
    <div class="header mgr-header">
        <mat-toolbar color="primary">
            <mat-toolbar-row>
                <button mat-button (click)="checkAuth()">Check Auth</button>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div> -->

<app-customers></app-customers>