import { Component, OnInit } from '@angular/core';

import { LabourService, LabourDataService } from "../../../services";

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-labour-list',
  templateUrl: './labour-list.component.html',
  styleUrls: ['./labour-list.component.scss']
})
export class LabourListComponent implements OnInit {

  lbrList$: Observable<any>;

  constructor(private lbService: LabourService, 
    private lbrDataService: LabourDataService) { }

  ngOnInit(): void {
    this.lbrList$ = this.lbService.getLabourDetailsList().pipe(
      map((lbrEntries) => lbrEntries.sort((a, b) => b["id"] - a["id"])) 
    );
  }

  editDetails(lbr) {
    this.lbrDataService.captureId(lbr.id);
  }

}
