import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { UntypedFormArray } from '@angular/forms';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { LabourService, LabourDataService } from "../../../services";

@Component({
  selector: 'app-labour-form',
  templateUrl: './labour-form.component.html',
  styleUrls: ['./labour-form.component.scss']
})
export class LabourFormComponent implements OnInit {

  formTitle: string;
  labourDetails: UntypedFormGroup;
  categoryOptions: labelValue[];
  subCategoryOptions: labelValue[];

  detailsSubmitted: boolean = false;

  constructor(private fb: UntypedFormBuilder,
    private lbService: LabourService,
    private lbrDataService: LabourDataService) {

    this.labourDetails = this.fb.group({
      date: ['', Validators.required],
      total: [''],
      groups: this.fb.array([])
    });

    this.categoryOptions = [
      { label: "Skilled", value: "Skilled" },
      { label: "UnSkilled", value: "UnSkilled" },
      { label: "Other", value: "Other" }
    ];

    this.subCategoryOptions = [
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" }
    ];

  }

  ngOnInit(): void {
    // Handle form changes
    this.onChanges();
    // Subscribe for lbr id for editing details
    this.lbrDataService.id$.subscribe(val => {
      this.editLbrDetails(val);
    });
  }

  getDateId(dt) {
    let date = new Date(dt);
    let currentYear = date.getFullYear().toString();
    let currentMonth = (date.getMonth() + 1).toString();
    if (parseInt(currentMonth) < 10) {
      currentMonth = `0${currentMonth}`;
    }
    let currDate = date.getDate().toString();
    if (parseInt(currDate) < 10) {
      currDate = `0${currDate}`;
    }

    return currentYear + currentMonth + currDate;
  }

  get groups() {
    return this.labourDetails.get("groups") as UntypedFormArray;
  }

  newGroup() {
    return this.fb.group({
      name: [''],
      count: [''],
      total: [''],
      categories: this.fb.array([]),
      note: ['']
    });
  }

  newCategory() {
    return this.fb.group({
      category: [''],
      count: [''],
      subCategories: this.fb.array([]),
      total: [''],
      note: [''],
      hideCount: [false]
    });
  }

  newSubCategory() {
    return this.fb.group({
      subCategory: '',
      count: '',
      note: ''
    });
  }

  addGroup() {
    this.groups.push(this.newGroup());
  }

  addCategory(g) {
    const group = this.groups.controls[g];
    const cat = group.get("categories") as UntypedFormArray;
    cat.push(this.newCategory());
  }

  addSubCategory(g, i) {
    const group = this.groups.controls[g];
    const cat = group.get("categories")["controls"][i] as UntypedFormGroup;
    cat.get("hideCount").setValue(true, { emitEvent: false });
    cat.get("count").setValue(0);
    const subc = cat.get("subCategories") as UntypedFormArray;
    subc.push(this.newSubCategory());
  }

  // Remove group
  removeGroup(g) {
    this.groups.removeAt(g);
  }

  // Remove category
  removeCat(g, i) {
    const control = <UntypedFormArray>this.groups.controls[g].get("categories");
    control.removeAt(i);
  }

  // Remove sub category
  removeSubCat(g, i, j) {
    const group = this.groups.controls[g];
    const cat = <UntypedFormGroup>group.get("categories")["controls"][i];
    const subCat = <UntypedFormArray>cat.get("subCategories");
    subCat.removeAt(j);
    // Set hideCount on category to false to display Count field.
    if (subCat.length > 0) return;
    cat.get("hideCount").setValue(false, { emitEvent: false });
  }

  // Edit details
  editLbrDetails(id) {
    this.lbService.getLabourDetails(id)
      .then((snapshot) => {
        let val = snapshot.val();
        if (!val) return;
        let lbrDetails: UntypedFormGroup = this.fb.group({});
        let groups: UntypedFormArray = this.fb.array([]);

        // Data conversion from categories to groups
        // At first level. Create a group if doesn't exist
        if (!val.groups) {
          val.groups = new Array({
            name: "New Group",
            total: val.total,
            count: "",
            note: "",
            categories: val.categories
          });
          delete val.categories;
        }

        val.groups.forEach(group => {
          // Loop through categories
          let cats: UntypedFormArray = this.fb.array([]);

          group.categories.forEach(cat => {
            if (!cat.note) { cat.note = ""; }
            // Loop through sub categories
            if (typeof cat.subCategories !== "undefined") {
              let subCats: UntypedFormArray = this.fb.array([]);

              cat.subCategories.forEach(sub => {
                if (!sub.note) { sub.note = ""; }
                subCats.push(this.fb.group(sub)); // Build sub categories
              });
              cat.subCategories = subCats;
            }
            else {
              // Handle category with no subcategories
              cat.subCategories = this.fb.array([]);
            }
            cats.push(this.fb.group(cat)); // Add sub categories to categories
          });
          group.categories = cats;
          groups.push(this.fb.group(group)); // Add categories to groups
        });
        lbrDetails.addControl("groups", groups);
        lbrDetails.addControl("id", this.fb.control(val.id));
        lbrDetails.addControl("total", this.fb.control(val.total));
        lbrDetails.addControl("date", this.fb.control(val.date));
        lbrDetails.addControl("mode", this.fb.control("edit"));
        this.labourDetails = lbrDetails;
        this.detailsSubmitted = false;
        this.onChanges();
        window.scroll({
          top: 0,
          behavior: "smooth"
        });
      })
      .catch((error) => { console.log("Error while getting details", error) });
  }

  // Date change
  onDateChange(eventtype: string, event: MatDatepickerInputEvent<Date>) {
    let date = new Date(event.target.value);
    let currentYear = date.getFullYear().toString();
    let currentMonth = (date.getMonth() + 1).toString();
    if (parseInt(currentMonth) < 10) {
      currentMonth = `0${currentMonth}`;
    }
    let currDate = date.getDate().toString();
    if (parseInt(currDate) < 10) {
      currDate = `0${currDate}`;
    }
    let dateString = currentYear + currentMonth + currDate;

    this.editLbrDetails(dateString);

  }

  onChanges() {
    // Calculate counts
    this.groups.valueChanges.subscribe(val => {
      // Loop through groups
      let total = val.reduce((acc, cur, g) => {

        // Loop through categories in cX format
        let ctotal = cur.categories.reduce((cacc, ccur, i) => {

          // Loop through sub categories in scX format
          let sctotal = ccur.subCategories.reduce((scacc, sccur, j) => {
            return (parseInt(scacc || 0) + parseInt(sccur.count || 0));
          }, 0) || ccur.count; // Set to sum of sub Cats or self count.

          // Set total on Category
          this.groups["controls"][g].get("categories")["controls"][i].get("total").setValue(sctotal, { emitEvent: false });
          // Return sum of sctotals
          return (parseInt(cacc || 0) + parseInt(sctotal || 0));
        }, 0) || 0; // Set to sum of children count or 0 

        // Set sum of totals on group
        this.groups["controls"][g].get("total").setValue(ctotal, { emitEvent: false });
        // Return sum of totals
        return (parseInt(acc || 0)) + parseInt(ctotal || 0);
      }, 0) || 0;

      this.labourDetails.get("total").setValue(total, { emitEvent: false });
    });
  } // End of onChanges

  saveLabourDetails() {
    if (this.labourDetails.invalid) return;
    let details = this.labourDetails.value;
    details.date = typeof details.date === "string" ? details.date : details.date.toJSON();
    if (details.mode !== "" && details.mode === "edit") { // Edit details
      this.lbService.editLabourDetails(details)
        .then(() => {
          this.afterSaveDetails();
        });
    }
    else { // New details
      details.id = this.getDateId(details.date);
      this.lbService.addLabourDetails(details)
        .then(() => {
          this.afterSaveDetails();
        });
    }

  }

  afterSaveDetails() {
    this.detailsSubmitted = true;
    this.labourDetails.reset();
    window.scroll({
      top: 0,
      behavior: "smooth"
    });
  }

}

interface labelValue {
  label: string,
  value: string
}
