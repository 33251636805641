<app-appheader></app-appheader>
<div class="container app-home">
    <div class="cmp-list">
        <div id="invoice-cmp" class="cmp-box" matRipple [matRippleCentered]="true" [routerLink]="['/invoice']"
            *ngIf="showInvoice">
            <span class="cmp-name">Invoice</span>
        </div>
        <div id="ledger-cmp" class="cmp-box" matRipple [matRippleCentered]="true" [routerLink]="['/ledger']"
            *ngIf="showLedger">
            <span class="cmp-name">Ledger</span>
        </div>
        <div id="inv-cmp" class="cmp-box" matRipple [matRippleCentered]="true" [routerLink]="['/inv']"
            *ngIf="showInventory">
            <span class="cmp-name">Inventory</span>
        </div>
        <div id="labour-cmp" class="cmp-box" matRipple [matRippleCentered]="true" [routerLink]="['/lbr']"
            *ngIf="showLabour">
            <span class="cmp-name">Labour</span>
        </div>
        <div id="manager-cmp" class="cmp-box" matRipple [matRippleCentered]="true" [routerLink]="['/manager']"
            *ngIf="showManager">
            <span class="cmp-name">Manager</span>
        </div>
        <div id="sauda-cmp" class="cmp-box" matRipple [matRippleCentered]="true" [routerLink]="['/sauda']"
            *ngIf="showSauda">
            <span class="cmp-name">Sauda</span>
        </div>
        <div id="reports-cmp" class="cmp-box" matRipple [matRippleCentered]="true" [routerLink]="['/reports']"
            *ngIf="showReports">
            <span class="cmp-name">Reports</span>
        </div>
    </div>
</div>