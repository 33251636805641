import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../services/auth.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SgService {

  // serviceURL = "http://localhost:3000/";
  serviceURL = "https://api.santosh.app/";
  sgServiceURL = environment.sgApiUrl;
  headers = { 'Content-Type': 'application/json' };

  constructor(private http: HttpClient,
    private authService: AuthService) {
    this.getCurrentUserIdToken();
    this.checkAuth();
  }

  getCurrentUserIdToken() {
    this.authService.currentUser()
      .then((user) => {
        return user.getIdToken(true);
      })
      .then((idToken) => {
        this.headers["Authorization"] = idToken;
      })
      .catch((error) => {
        console.log(`Error in sg service current user ${error}`);
      });
  }

  async getUserToken() {
    const user = await this.authService.currentUser();
    const token = await user.getIdToken(true);
    return token;
  }

  checkAuth() {
    this.authService.currentUser()
      .then((user) => {
        user.getIdToken(true)
          .then((idToken) => {
            // console.log("Token is", idToken);
            this.http.get(`${this.sgServiceURL}auth`, {
              headers: {
                'Authorization': idToken
              }
            }).subscribe((response) => {
              // console.log("Response from api is", response);
            });
          });
      });
  }

  // Send text for new invoice
  sendInvoiceText(invNumber) {
    const body = { invNumber: invNumber };
    const options = { headers: this.headers };
    return this.http.post(this.serviceURL + "text/invText", body, options);
  }

  // Download Invoice PDF
  async downloadInvoicePDF(id) {
    if (!id) return;
    const token = await this.getUserToken();
    this.headers["Authorization"] = token;
    const options = {
      headers: this.headers,
      responseType: 'blob' as 'blob'
    };
    const pdfUrl = `${this.sgServiceURL}sg1/invpdf/${id}`;
    return this.http.get(pdfUrl, options);
  }

  // Get unique ID
  async getUniqueId(module) {
    if (!module) return;
    const token = await this.getUserToken();
    this.headers["Authorization"] = token;
    const options = {
      headers: this.headers,
      params: {
        module: module
      }
    };
    const idUrl = `${this.sgServiceURL}sg1/genops/id`;
    return this.http.get(idUrl, options);
  }

  /**
   * Get Sales and Purchases report by dates
   * @param {String} startDate
   * @param {String} endDate
   */
  async getPurchaseAndSalesReport(startDate, endDate) {
    const token = await this.getUserToken();
    this.headers["Authorization"] = token;
    const options = {
      headers: this.headers,
      params: {
        startDate: startDate,
        endDate: endDate
      }
    };
    const reportsUrl = `${this.sgServiceURL}sg1/reports/spreport`;
    return this.http.get(reportsUrl, options);
  }
}
