import { Injectable } from '@angular/core';

import { Firestore, collection, collectionData, query, where, orderBy, limit, addDoc, doc, getDoc, updateDoc } from '@angular/fire/firestore';

import { UserService } from "../services/user.service";

@Injectable({
    providedIn: 'root'
})
export class SaudaService {
    saudaColRef;
    saudaList$: any;
    saudaEntry$: any;
    localUserId: string;
    listQuery;
    openListQuery;

    constructor(private firestore: Firestore,
        private userService: UserService) {
        const saudaFirestoreRef: string = 'sauda';
        this.saudaColRef = collection(this.firestore, saudaFirestoreRef);
        this.listQuery = query(this.saudaColRef, orderBy('createDateTime', 'desc'), limit(25));
        this.openListQuery = query(this.saudaColRef, where('status', 'not-in', ['CANCEL', 'COMPLETE']), orderBy('status', 'desc'), orderBy('createDateTime', 'desc'), limit(25));
        // this.saudaList$ = collectionData(this.listQuery, {idField: 'id'});
        this.localUserId = this.userService.getLocalStorageOperator().uid;
    }

    // Get Sauda list
    getSaudaList() {
        return collectionData(this.listQuery, { idField: 'id' });
    }

    // Get Open Sauda list
    getOpenSaudaList() {
        return collectionData(this.openListQuery, { idField: 'id' });
    }

    // Get single Sauda
    getSaudaEntry(id) {
        return getDoc(doc(this.saudaColRef, id));
    }

    // Post new Sauda
    postNewSauda(obj) {
        obj.createOperatorId = this.localUserId;
        return addDoc(this.saudaColRef, obj);
    }

    // Update sauda entry
    updateSauda(id, data) {
        data.updateOperatorId = this.localUserId;
        return updateDoc(doc(this.saudaColRef, id), data);
    }
}