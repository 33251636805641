import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Defaults } from "./defaults";

export interface Address extends Defaults {
    addressLine1?: string,
    addressLine2?: string,
    city?: string,
    state?: string,
    pincode?: string,
    country?: string
}

export class AddressForm {
    AddressFormGroup: UntypedFormGroup;
    constructor() {
        this.AddressFormGroup = new UntypedFormGroup({
            name: new UntypedFormControl(''),
            addressLine1: new UntypedFormControl(''),
            addressLine2: new UntypedFormControl(''),
            city: new UntypedFormControl(''),
            state: new UntypedFormControl(''),
            stateCode: new UntypedFormControl(''),
            pincode: new UntypedFormControl(''),
            country: new UntypedFormControl('India'),
            phoneNumber: new UntypedFormControl(''),
            emailId: new UntypedFormControl('')
        });
    }
}