import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-modify-quantity',
  templateUrl: './modify-quantity.component.html',
  styleUrls: ['./modify-quantity.component.scss']
})
export class ModifyQuantityComponent implements OnInit {

  constructor(
    public qtyRef: MatDialogRef<ModifyQuantityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.qtyRef.close();
  }

}
