import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appheader',
  templateUrl: './appheader.component.html',
  styleUrls: ['./appheader.component.scss']
})
export class AppheaderComponent implements OnInit {

  headerTitle = '';

  constructor() { 
    this.headerTitle = "Santosh Ginning - App Studio";
  }

  ngOnInit(): void {
  }

}
