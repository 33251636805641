<mat-toolbar color="primary">
    <mat-toolbar-row class="centered">
        <button mat-icon-button (click)="goHome()"><mat-icon>home</mat-icon></button>
        <span class="toolbar-empty-space"></span>
        <span>Invoice <span *ngIf="invoice.tallyRefId && invoice.tallyRefId !== ''; else invoiceNumber">{{invoice.tallyRefId}}</span>
            <ng-template #invoiceNumber>{{invoice.invoiceNumber}}</ng-template></span>
        <span class="toolbar-empty-space"></span>
    </mat-toolbar-row>
</mat-toolbar>

<div class="container padding-lr-1x padding-b-1x margin-tb-1x">
    <h2><span class="label">Invoice Date: </span>{{invoice.createDateTime | date: 'medium'}}</h2>
    <div class="two-column-grid margin-b-1x">
        <div class="flex-box border-1x">
            <div class="div-content-header padding-lr-1x">
                <span>Customer Address</span>
            </div>
            <div class="div-content-body padding-1x">
                <p><span class="label">Name: </span>{{invoice.customerAddress.name}}</p>
                <p><span class="label">GST Number: </span>{{invoice.customerAddress.GSTNumber}}</p>
                <p><span class="label">GST State Code: </span>{{invoice.customerAddress.GSTStateCode}}</p>
                <p><span class="label">Address Line 1: </span>{{invoice.customerAddress.addressLine1}}</p>
                <p><span class="label">Address Line 2: </span>{{invoice.customerAddress.addressLine2}}</p>
                <p><span class="label">City: </span>{{invoice.customerAddress.city}}, {{invoice.customerAddress.state}},
                    {{invoice.customerAddress.pincode}}</p>
                <p><span class="label">Phone #: </span>{{invoice.customerAddress.phoneNumber}}</p>
                <p><span class="label">Email ID: </span>{{invoice.customerAddress.emailId}}</p>
            </div>
        </div>
        <div class="flex-box border-1x">
            <div class="div-content-header padding-lr-1x">
                <span>Delivery Address</span>
            </div>
            <div class="div-content-body padding-1x">
                <p><span class="label">Name: </span>{{invoice.deliveryAddress.name}}</p>
                <p><span class="label">GST Number: </span>{{invoice.deliveryAddress.GSTNumber}}</p>
                <p><span class="label">Address Line 1: </span>{{invoice.deliveryAddress.addressLine1}}</p>
                <p><span class="label">Address Line 2: </span>{{invoice.deliveryAddress.addressLine2}}</p>
                <p><span class="label">City: </span>{{invoice.deliveryAddress.city}}, {{invoice.deliveryAddress.state}},
                    {{invoice.deliveryAddress.pincode}}</p>
                <p><span class="label">Phone #: </span>{{invoice.deliveryAddress.phoneNumber}}</p>
            </div>
        </div>
    </div>
    <div class="margin-tb-1x border-1x">
        <div class="div-content-header padding-lr-1x">
            <span>Bill & Terms</span>
        </div>
        <div class="div-content-body padding-1x">
            <div class="two-column-grid">
                <div class="flex-box">
                    <p><span class="label">Way Bill #: </span>
                        {{invoice.wayBillNumber}}
                    </p>
                    <p><span class="label">LR #: </span>
                        {{invoice.lrNumber}}
                    </p>
                    <p><span class="label">Inco Terms: </span>
                        {{invoice.incoTerms}}
                    </p>
                </div>
                <div class="flex-box">
                    <p><span class="label">Payment Type: </span>
                        {{invoice.paymentType}}
                    </p>
                    <p><span class="label">Transport Mode: </span>
                        {{invoice.transportMode}}
                    </p>
                    <p><span class="label">Vehicle #: </span>
                        {{invoice.vehicleNumber}}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="margin-tb-1x border-1x">
        <div class="div-content-header padding-lr-1x">
            <span>Product & Details</span>
        </div>
        <div class="div-content-body padding-1x">
            <div class="two-column-grid">
                <div class="flex-box">
                    <p><span class="label">Agent: </span>
                        {{invoice.agentName}}
                    </p>
                    <p><span class="label">Sale Confirmation Id: </span>
                        {{invoice.saleConfirmation}}
                    </p>
                    <p><span class="label">Product:</span>
                        <span *ngIf="invoice.product==='B' else seedBlock">Bales</span>
                        <ng-template #seedBlock>
                            Seed
                        </ng-template>
                    </p>
                    <p><span class="label">HSN Code:</span>
                        {{invoice.HSN}}
                    </p>
                    <p><span class="label">Press Mark:</span>
                        {{invoice.pressMark}}
                    </p>
                    <p><span class="label">PR Number:</span>
                        {{invoice.prNumber}}
                    </p>
                </div>
                <div class="flex-box">
                    <p><span class="label">Lot Numbers:</span>
                        {{invoice.lotNumbers}}
                    </p>
                    <p><span class="label">Rate Per Candy(Bales):</span>
                        {{invoice.ratePerCandy | inr}}
                    </p>
                    <p><span class="label">Rate Per KG:</span>
                        {{invoice.ratePerKG | inr}}
                    </p>
                    <p><span class="label">Total Net KGS:</span>
                        {{invoice.totalNetKGS}}
                    </p>
                    <p><span class="label">Number Of Bales:</span>
                        {{invoice.baleCount}}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="margin-tb-1x border-1x">
        <div class="div-content-header padding-lr-1x">
            <span>Additional Items</span>
        </div>
        <div class="div-content-body padding-1x">
            <mat-table [dataSource]="invoice.additionalItems">
                <ng-container matColumnDef="HSN">
                    <mat-header-cell *matHeaderCellDef>HSN</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.HSN}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Count">
                    <mat-header-cell *matHeaderCellDef>Count</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.count}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Description">
                    <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.description}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Price">
                    <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.price | inr}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="additionalItemsColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns:additionalItemsColumns;"></mat-row>
            </mat-table>
        </div>
    </div>
    <div class="margin-tb-1x border-1x">
        <div class="div-content-header padding-lr-1x">
            <span>Note</span>
        </div>
        <div class="div-content-body padding-1x">
            <p>{{invoice.notes}}</p>
        </div>
    </div>
    <div class="margin-tb-1x border-1x">
        <div class="div-content-header padding-lr-1x">
            <span>Pricing Details</span>
        </div>
        <div class="div-content-body padding-1x">
            <p><span class="label">Price: </span>
                {{invoice.price | inr}}
            </p>
            <p><span class="label">Additional Items Price: </span>
                {{invoice.additionalItemsPrice | inr}}
            </p>
            <p><span class="label">Advance Amount: </span>
                {{invoice.advanceAmount | inr}}
            </p>
            <p><span class="label">SGST 2.5%: </span>
                {{invoice.SGST | inr}}
            </p>
            <p><span class="label">CGST 2.5%: </span>
                {{invoice.CGST | inr}}
            </p>
            <p><span class="label">IGST 5%: </span>
                {{invoice.IGST | inr}}
            </p>
            <p><span class="label">Total Price: </span>
                <strong>{{invoice.totalPrice | inr}}</strong>
            </p>
        </div>
    </div>
    <!--
    <div class="margin-tb-1x border-1x">
        <div class="div-content-header padding-lr-1x">
            <span>Payment Records</span>
        </div>
        <div class="div-content-body padding-1x">
            <mat-table [dataSource]="invoice.payments">
                <ng-container matColumnDef="Date">
                    <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.transactionDate | date: 'mediumDate'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Amount">
                    <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.amount | inr}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Reference">
                    <mat-header-cell *matHeaderCellDef>Reference</mat-header-cell>
                    <mat-cell *matCellDef="let item">{{item.reference}}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="paymentsColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns:paymentsColumns;"></mat-row>
            </mat-table>
        </div>
    </div> -->
    <div class="button-section">
        <button mat-flat-button color="primary" (click)="download(invoice.invoiceNumber)">Dowload Invoice</button>
        <!-- <button mat-raised-button color="primary" (click)="openCreditDialog()">Add Credit</button> -->
        <!-- <button mat-raised-button color="primary" (click)="openPaymentDialog()">Record Payment</button> -->
    </div>
</div>