<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button mat-icon-button [routerLink]="['/sauda']">
            <mat-icon>home</mat-icon>
        </button>
        <span>Sauda {{ sauda.refId }}</span>
        <span class="toolbar-empty-space"></span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="container padding-lr-1x padding-b-1x margin-tb-1x">
    <div class="flex-box" *ngIf="saudaSubmitted!==true; else formSubmitted">
        <p><span class="label">Created On: </span>{{ sauda.createDateTime | date }}</p>
        <p><span class="label">Sauda Date: </span>{{ sauda.saudaDateTime | date }}</p>
        <p><span class="label">Product: </span>
            <span *ngIf="sauda.product==='B' else seedBlock">Bales</span>
            <ng-template #seedBlock>
                <span *ngIf="sauda.product==='S' else otherBlock">Seed</span>
            </ng-template>
            <ng-template #otherBlock>
                <span>Other</span>
            </ng-template>
        </p>
        <p><span class="label">Agent: </span>{{ sauda.agent }}</p>
        <p><span class="label">Company: </span>{{ sauda.company }}</p>
        <p><span class="label">Price: </span>{{ sauda.price | inr }}</p>
        <p><span class="label">Status: </span>
            <mat-form-field>
                <mat-select [(ngModel)]="sauda.status">
                    <mat-option *ngFor="let status of statuses" [value]="status">{{ status }}</mat-option>
                </mat-select>
            </mat-form-field>
        </p>
        <p><span class="label">Notes: </span>{{ sauda.note }}</p>
        <div class="button-section">
            <button mat-flat-button color="accent" [routerLink]="['/sauda']">Cancel</button>
            <button mat-flat-button color="primary" (click)="updateSauda(id)">Update</button>
        </div>
    </div>
    <ng-template #formSubmitted>
        <div class="action-success centered">
            <div>
                <mat-icon>check_circle_outline</mat-icon>
            </div>
            <p>Success</p>
            <div class="button-section">
                <button mat-flat-button color="primary" [routerLink]="['/sauda']">Dashboard</button>
            </div>
        </div>
    </ng-template>
</div>