<h1 mat-dialog-title>Add Product</h1>
<div mat-dialog-content>
    <div>
        <mat-form-field>
            <mat-label>Enter Name</mat-label>
            <input matInput required [(ngModel)] = "data.name">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>Select Units</mat-label>
            <mat-select required [(ngModel)] = "data.units">
                <mat-option *ngFor="let unit of unitKeys" 
                [value] = "unit">
                    {{ unit }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="data"  
    cdkFocusInitial>Submit</button>
</div>