import { Component, OnInit } from '@angular/core';

import { SgService } from '../../services/sg.service';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements OnInit {

  constructor(private sgService: SgService) { }

  ngOnInit(): void {
    
  }
}
