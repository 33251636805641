import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  showInventory:boolean = false;
  showLabour:boolean = false;
  showLedger: boolean = true;
  showManager: boolean = false;
  showInvoice: boolean = true;
  showReports: boolean = true;
  showCustomers: boolean = false;
  showSauda: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
