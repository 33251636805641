<div class="ledger-options">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <button mat-icon-button [routerLink]="['/']">
                <mat-icon>home</mat-icon>
            </button>
            <span>Ledger Dashboard</span>
            <span class="toolbar-empty-space"></span>
        </mat-toolbar-row>
    </mat-toolbar>
    <div class="container padding-lr-1x padding-b-1x margin-tb-1x">
        <h1 mat-dialog-title>Enter Start and End Dates</h1>
        <div class="two-column-grid">
            <mat-form-field>
                <mat-label>Start Date</mat-label>
                <input matInput [(ngModel)]="reportStartDate" required readonly [max]="dateLimit"
                    [matDatepicker]="rsDate">
                <mat-datepicker-toggle matSuffix [for]="rsDate"></mat-datepicker-toggle>
                <mat-datepicker #rsDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>End Date</mat-label>
                <input matInput [(ngModel)]="reportEndDate" required readonly [max]="dateLimit"
                    [matDatepicker]="reDate">
                <mat-datepicker-toggle matSuffix [for]="reDate"></mat-datepicker-toggle>
                <mat-datepicker #reDate></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="button-section padding-b-1x">
            <button mat-flat-button color="primary" (click)="getReportObjByDates()">Show Report</button>
            <!-- <button mat-flat-button color="accent" (click)="gotoLedgerMain()">Show List</button> -->
            <button mat-flat-button color="accent" (click)="getListByDates()">Show List</button>
            <button mat-flat-button color="primary" (click)="newCottonTransaction()">Record Purchase</button>
        </div>
        <div class="button-section padding-b-1x">
            <!-- <button mat-flat-button color="primary" (click)="newTransaction()">New Entry</button> -->
        </div>
        <div class="centered" *ngIf="displayModule == 'default'">
            <p>Select date range and click on report or list to display reports or transactions</p>
        </div>
        <div class="report-cards-box" *ngIf="displayModule == 'report'">
            <div class="report-cards">
                <mat-card appearance="outlined" class="margin-b-1x">
                    <mat-card-header>
                        <mat-card-title>TOTAL Metrics</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="report-detail">Total Weight - {{ reportObj.totalWeight | number:'1.0-2' }} Quintals
                        </div>
                        <div class="report-detail">Total Price - {{ reportObj.totalPrice | inr }}</div>
                        <div class="report-detail">Average Price - {{ reportObj.averagePrice | inr }}</div>
                        <div class="report-detail">Loose Count - {{ reportObj.looseCount }}</div>
                        <div class="report-detail">Tender Count - {{ reportObj.tenderCount }}</div>
                        <div class="report-detail">Tender Checks - {{ reportObj.tenderChecks }}</div>
                        <div class="report-detail">Tender Bags(Reg) - {{ reportObj.tenderRegBags }}</div>
                        <div class="report-detail">Tender Bags(Small) - {{ reportObj.tenderSmallBags }}</div>
                        <div class="report-detail">Farmers Price - {{ reportObj.farmersPrice | inr }}</div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="report-cards quality-cats">
                <div class="quality-cat" *ngFor="let cat of reportObj['qualityCats'] | keyvalue">
                    <mat-card appearance="outlined" class="margin-b-1x">
                        <mat-card-header>
                            <mat-card-title>{{ cat.key }} Quality</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="report-detail">Total Weight - {{ cat.value.qualityTotalWeight | number:'1.0-2'
                                }} Quintals</div>
                            <div class="report-detail">Total Price - {{ cat.value.qualityTotalPrice | inr }}</div>
                            <div class="report-detail">Average Price - {{ cat.value.qualityAveragePrice | inr }}</div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>

        <div class="list-box" *ngIf="displayModule == 'list'">
            <div class="container">
                <h1>List of Transactions</h1>
                <div class="transactions-listbox">
                    <mat-form-field>
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Farmer" #input>
                    </mat-form-field>
                    <table mat-table [dataSource]="TransactionsSource" matSort matSortActive="transactionDate"
                        matSortDirection="desc">

                        <ng-container matColumnDef="transactionDate">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.transactionDate | date}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="refId">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Ref ID </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.refId}} ({{ element.purchaseType }})
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="payeeType">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.payeeType}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="payee">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Payee</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.payee}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="amount">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Amount (AW * RPU) </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.amount | inr}} ({{element.adjustedWeight}} *
                                {{element.ratePerUnit | inr}}) </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="delete">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <button mat-icon-button color="primary" (click)="deleteEntry(element)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="TransactionColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: TransactionColumns;"></mat-row>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>