<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button mat-icon-button
        [routerLink]="['/']"><mat-icon>home</mat-icon></button>
        <span>Inventory</span>
    </mat-toolbar-row>
</mat-toolbar>

<div class="container">
    <app-product></app-product>
    <app-products></app-products>
</div>