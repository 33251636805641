import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { InventoryService } from "../../services/inventory.service";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-product-log',
  templateUrl: './product-log.component.html',
  styleUrls: ['./product-log.component.scss']
})
export class ProductLogComponent implements OnInit {

  name: string;
  logs$: Observable<any>;

  constructor(private ngZone: NgZone, 
    private router: Router, 
    private route: ActivatedRoute,
    private invService: InventoryService) { 
    this.name = "";
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: any) => {
      if(!params) return;
      this.logs$ = this.invService.getInventoryLogByProduct(params.get("pid")).pipe(
        map((logEntries) => logEntries.sort((a, b) => b["createDateTime"] - a["createDateTime"]))
      );
      // Get product details
      this.invService.getProductDetails(params.get("pid")).subscribe((product) => {
        this.name = product["name"];
      });
    });
  }

  goHome() {
    this.ngZone.run(() => {
      this.router.navigate(["/inv"]);
    });
  }

}
