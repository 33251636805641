import { Injectable } from '@angular/core';
import { ArgumentOutOfRangeError, Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';


@Injectable({
  providedIn: 'root'
})
export class LedgerDataService {
  ldrPath: string = "ledger/data";
  ldrRef: any;
  ledger$: Observable<any>;

  constructor(private db: AngularFireDatabase) {
    // this.getTransactions();
    // this.getTransactionsWithDateRange(new Date().toISOString(), new Date().toISOString());
  }

  getTransactions() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    console.log(new Date(tomorrow).toJSON());
    this.ledger$ = this.db.list(this.ldrPath, ref => ref.orderByChild('transactionDate').startAt("").endAt(tomorrow.toJSON().slice(0, 10))).valueChanges();
    this.ledger$.subscribe((data) => {
      console.log(data);
    });
  }

  getTransactionsWithDateRange(startDate, endDate) {
    if (startDate && endDate) {
      return this.ledger$ = this.db.list(this.ldrPath, ref =>
        ref.orderByChild('transactionDate').startAt(startDate).endAt(endDate)).valueChanges();
    }
    else {
      throw "Invalid dates";
    }
  }
}
