import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';

import { User } from "../models/user";

@Injectable({
  providedIn: 'root'
})

export class UserService {
  user: User;
  usersRef: firebase.default.database.Reference;
  users: Observable<User[]>; // Used for all users list

  constructor(private db: AngularFireDatabase) {
    this.usersRef = db.database.ref("operators");
  }

  isAuthorized(user: any): Promise<boolean> {
    // return boolean
    return this.usersRef.child(user.uid).once("value")
      .then((snapshot) => {
        if (!snapshot) return false;
        if (snapshot.val().authorized) return true;
        return false;
      })
      .catch((error) => { return false; });
    //return true;
  }

  isAuthorizedWithRoles(user: any, role?: number): Promise<boolean> {
    // return boolean
    return this.usersRef.child(user.uid).once("value")
      .then((snapshot) => {
        if (!snapshot) return false;
        const data = snapshot.val();
        if (!data.authorized) return false;
        if (!data.role) return false;
        let hasRole = false;
        if (data['role'] !== undefined && data['role'] <= role) {
          hasRole = true;
        }

        return hasRole;
      })
      .catch((error) => { return false; });
    //return true;
  }

  getUserDetails(id: string) {
    // Get user details
    if (!id) return;
    return this.db.object("operators/" + id).valueChanges();
  }

  saveNewUser(user: User) {
    // Check if user exists. If not save new
    if (!user) return;
    console.log("Adding new user", user);
    user.createDateTime = new Date().getTime();
    user.authorized = false;
    return this.usersRef.child(user.id).set(user);
  }

  getLocalStorageOperator() {
    // Get current operator from local storage
    return JSON.parse(localStorage.getItem("currentUser"));
  }

}
