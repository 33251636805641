<div class="container padding-1x">
    <h1>List of Transactions</h1>
    <div class="transactions-listbox mat-elevation-z8">
        <table mat-table [dataSource]="TransactionsSource" matSort matSortActive="transactionDate"
            matSortDirection="desc">

            <ng-container matColumnDef="transactionDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.transactionDate | date}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="refId">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Ref ID </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.refId}} ({{ element.purchaseType }}) </mat-cell>
            </ng-container>
            <ng-container matColumnDef="payee">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Payee</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.payee}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.amount | inr}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="amountSource">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Source </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.amountSource}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="delete">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button color="primary" (click)="deleteEntry(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="TransactionColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: TransactionColumns;"></mat-row>
        </table>
    </div>

    <!-- Mat List -->
    <!-- <mat-list class="ledger-list">
        <mat-list-item *ngFor="let item of TList$ | async">
            <div class="list-item margin-tb-1x border-1x">
                <div class="div-content-header padding-lr-1x">
                    <span>Payee: {{ item.payee }}</span>
                </div>
                <div class="flex-box div-content-body padding-1x">
                    <p><span class="label">Date: </span>
                        {{item.transactionDate | date}}
                    </p>
                    <p><span class="label">Reference: </span>
                        {{item.refId}}
                    </p>
                    <p><span class="label">Type: </span>
                        {{item.purchaseType}}
                    </p>
                    <p><span class="label">Amount: </span>
                        {{item.amount | inr}}
                    </p>
                    <button mat-stroked-button color="primary" (click)="deleteEntry(item)">
                        Delete
                    </button>
                </div>
            </div>
        </mat-list-item>
    </mat-list> -->
    <!-- End of Mat List -->
</div>